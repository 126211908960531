import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: {
    base: css`
      margin-bottom: 16px;

      display: flex;
      flex-direction: column;

      white-space: pre-wrap;
      word-break: break-word;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: ${Colors.DARK_7};

      &:last-of-type {
        margin-bottom: 0;
      }
    `,

    disabled: css`
      opacity: 0.6;
      pointer-events: none;
    `,
  },

  inputContainer: (editing: boolean) => css`
    display: flex;
    align-items: center;
    margin-top: ${editing && '5px'};
  `,

  title: {
    base: css`
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: ${Colors.DARK_1};
    `,

    required: css`
      color: ${Colors.DARK_8};
    `,

    displaying: css`
      margin-bottom: 8px;
    `,
  },

  input: css`
    padding: 8px;

    font-weight: normal;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    color: ${Colors.DARK_7};

    background: ${Colors.LIGHT_10};
    border-radius: 5px;
    border: none;
    outline: none;

    &::placeholder {
      color: ${Colors.DARK_3};
    }
  `,

  colorInputContainer: (color: string) => css`
    float: left;
    border-radius: 3px;
    margin-right: 12px;
    background: ${color};
    box-shadow: 0px 0px 3px ${Colors.DARK_6};
  `,

  colorInput: (displaying: boolean) => css`
    opacity: 0;
    display: block;
    height: ${displaying ? '20px' : '33px'};
    width: 42px;
    border: none;
    cursor: ${!displaying && 'pointer'};
  `,

  textarea: css`
    resize: none;
  `,
}
