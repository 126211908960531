import { FC, Fragment, useContext, useEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useStore } from 'effector-react'

import { useAppDispatch, useAppSelector } from '~/shared/model'
import { ENABLE_STATUS_OPTIONS, EnableStatuses } from '~/shared/config/constants'
import { ApiContext } from '~/app/hoc/with-api'
import { Card, TextSelect, TextField, CardHeader } from '~/components'
import { Modes, CardHeaderTypes } from '~/types'
import { $integrationFields } from '~/entities/integration'

import { styles } from '../../styles'
import { StoreActions, storeSlice } from '../../redux'
import { MatchParams } from '../../types'
import {
  MARKETING_CHECKBOX_VISIBILITY_OPTIONS,
  MARKETING_CHECKBOX_DEFAULT_STATE_OPTIONS,
} from '../../constants'

export const CheckoutSettings: FC = () => {
  // get state and actions
  const dispatch = useAppDispatch()
  const integration = useStore($integrationFields)
  const { checkoutSettings } = useAppSelector((t) => t.store)

  // initialize actions
  const { api } = useContext(ApiContext)
  const storeActions = useMemo(() => new StoreActions(api), [api])

  // get route params
  const { params } = useRouteMatch<MatchParams>()

  // get checkout settings, set mode to displaying
  useEffect(() => {
    // get checkout settings
    dispatch(storeActions.getCheckoutSettings(params.storeId))

    // set mode to displaying
    dispatch(storeSlice.actions.setCheckoutSettingsMode(Modes.Displaying))
  }, [])

  // start checkout settings editing
  const editCheckoutSettings = (): void => {
    // set updated checkout settings
    dispatch(
      storeSlice.actions.setUpdatedCheckoutSettings(checkoutSettings.checkoutSettings),
    )

    // change mode
    dispatch(storeSlice.actions.setCheckoutSettingsMode(Modes.Editing))
  }

  // cancel checkout settings editing
  const cancelCheckoutSettingsEditing = (): void => {
    // set updated checkout settings to checkout settings from api
    dispatch(
      storeSlice.actions.setUpdatedCheckoutSettings(checkoutSettings.checkoutSettings),
    )

    // set mode to displaying
    dispatch(storeSlice.actions.setCheckoutSettingsMode(Modes.Displaying))
  }

  // save edited checkout settings
  const saveCheckoutSettings = (): void => {
    // update checkout settings request
    dispatch(
      storeActions.updateCheckoutSettings({
        storeId: params.storeId,
        checkoutSettings: checkoutSettings.updatedCheckoutSettings!,
      }),
    )
  }

  // disable save checkout settings button
  const disableSaveCheckoutSettings: boolean =
    checkoutSettings.loading ||
    Boolean(checkoutSettings.error) ||
    (checkoutSettings.updatedCheckoutSettings?.marketingCheckboxVisibility === 'show' &&
      !checkoutSettings.updatedCheckoutSettings?.marketingCheckboxText) ||
    (checkoutSettings.updatedCheckoutSettings?.needHelpEnableStatus ===
      EnableStatuses.Enabled &&
      (!checkoutSettings.updatedCheckoutSettings?.needHelpHeading ||
        !checkoutSettings.updatedCheckoutSettings?.needHelpTitle ||
        !checkoutSettings.updatedCheckoutSettings?.needHelpDescription))

  return (
    <Card
      loading={checkoutSettings.loading && checkoutSettings.mode === Modes.Displaying}
      error={checkoutSettings.error}
      showContent={
        checkoutSettings.mode === Modes.Editing ||
        (checkoutSettings.mode === Modes.Displaying && !checkoutSettings.error)
      }
      extendStyle={{ body: styles.cardBody }}
      header={
        <CardHeader
          mode={checkoutSettings.mode}
          type={CardHeaderTypes.Edit}
          title="SETTINGS"
          onEdit={editCheckoutSettings}
          disableEditButton={Boolean(checkoutSettings.error)}
          onSave={saveCheckoutSettings}
          disableSaveButton={disableSaveCheckoutSettings}
          onDiscard={cancelCheckoutSettingsEditing}
        />
      }
    >
      <TextSelect
        title="Marketing checkbox visibility"
        value={checkoutSettings.checkoutSettings?.marketingCheckboxVisibility}
        mode={checkoutSettings.mode}
        placeholder="Select marketing checkbox visibility"
        selectOptions={MARKETING_CHECKBOX_VISIBILITY_OPTIONS}
        selectValue={
          checkoutSettings.updatedCheckoutSettings?.marketingCheckboxVisibility
        }
        onChange={(marketingCheckboxVisibility) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              marketingCheckboxVisibility,
            }),
          )
        }
      />
      <TextSelect
        title="Marketing checkbox default state"
        value={checkoutSettings.checkoutSettings?.marketingCheckboxDefaultState}
        mode={checkoutSettings.mode}
        placeholder="Select marketing checkbox default state"
        selectOptions={MARKETING_CHECKBOX_DEFAULT_STATE_OPTIONS}
        selectValue={
          checkoutSettings.updatedCheckoutSettings?.marketingCheckboxDefaultState
        }
        onChange={(marketingCheckboxDefaultState) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              marketingCheckboxDefaultState,
            }),
          )
        }
      />
      <TextField
        title="Marketing checkbox text"
        value={checkoutSettings.checkoutSettings?.marketingCheckboxText}
        mode={checkoutSettings.mode}
        placeholder="Marketing checkbox text"
        inputValue={checkoutSettings.updatedCheckoutSettings?.marketingCheckboxText}
        required
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              marketingCheckboxText: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Terms and conditions link"
        value={checkoutSettings.checkoutSettings?.termsAndConditionsLink}
        mode={checkoutSettings.mode}
        placeholder="Terms and conditions link"
        inputValue={checkoutSettings.updatedCheckoutSettings?.termsAndConditionsLink}
        required
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              termsAndConditionsLink: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Privacy policy link"
        value={checkoutSettings.checkoutSettings?.privatePolicyLink}
        mode={checkoutSettings.mode}
        placeholder="Privacy policy link"
        inputValue={checkoutSettings.updatedCheckoutSettings?.privatePolicyLink}
        required
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              privatePolicyLink: e.target.value,
            }),
          )
        }
      />
      <TextSelect
        title="Coupons"
        value={checkoutSettings.checkoutSettings?.couponsEnableStatus}
        mode={checkoutSettings.mode}
        selectOptions={ENABLE_STATUS_OPTIONS}
        selectValue={checkoutSettings.updatedCheckoutSettings?.couponsEnableStatus}
        onChange={(couponsEnableStatus) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              couponsEnableStatus,
            }),
          )
        }
      />
      <TextSelect
        title="Need help section"
        value={checkoutSettings.checkoutSettings?.needHelpEnableStatus}
        mode={checkoutSettings.mode}
        selectOptions={ENABLE_STATUS_OPTIONS}
        selectValue={checkoutSettings.updatedCheckoutSettings?.needHelpEnableStatus}
        onChange={(needHelpEnableStatus) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              needHelpEnableStatus,
            }),
          )
        }
      />
      <TextField
        title="Need help heading"
        disabled={
          checkoutSettings.updatedCheckoutSettings?.needHelpEnableStatus ===
            EnableStatuses.Disabled && checkoutSettings.mode === Modes.Editing
        }
        value={checkoutSettings.checkoutSettings?.needHelpHeading}
        mode={checkoutSettings.mode}
        placeholder="Need help?"
        required
        inputValue={checkoutSettings.updatedCheckoutSettings?.needHelpHeading}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              needHelpHeading: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Need help title"
        disabled={
          checkoutSettings.updatedCheckoutSettings?.needHelpEnableStatus ===
            EnableStatuses.Disabled && checkoutSettings.mode === Modes.Editing
        }
        value={checkoutSettings.checkoutSettings?.needHelpTitle}
        mode={checkoutSettings.mode}
        placeholder="Call us on 1-800-123-456"
        required
        inputValue={checkoutSettings.updatedCheckoutSettings?.needHelpTitle}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              needHelpTitle: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Need help description"
        disabled={
          checkoutSettings.updatedCheckoutSettings?.needHelpEnableStatus ===
            EnableStatuses.Disabled && checkoutSettings.mode === Modes.Editing
        }
        value={checkoutSettings.checkoutSettings?.needHelpDescription}
        mode={checkoutSettings.mode}
        placeholder="Lines open Mon-Fri 9am-5pm ET"
        required
        inputValue={checkoutSettings.updatedCheckoutSettings?.needHelpDescription}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              needHelpDescription: e.target.value,
            }),
          )
        }
      />
      <TextSelect
        title="Saving details"
        value={checkoutSettings.checkoutSettings?.savingDetailsEnableStatus}
        mode={checkoutSettings.mode}
        selectOptions={ENABLE_STATUS_OPTIONS}
        selectValue={checkoutSettings.updatedCheckoutSettings?.savingDetailsEnableStatus}
        onChange={(savingDetailsEnableStatus) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              savingDetailsEnableStatus,
            }),
          )
        }
      />

      <TextField
        title="Google analytics v3 measurement id"
        value={checkoutSettings.checkoutSettings?.googleAnalyticsV3Id}
        mode={checkoutSettings.mode}
        useTextarea
        placeholder="UA-xxxxx-y"
        inputValue={checkoutSettings.updatedCheckoutSettings?.googleAnalyticsV3Id}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              googleAnalyticsV3Id: e.target.value,
            }),
          )
        }
      />

      <TextField
        title="Google analytics v4 measurement id"
        value={checkoutSettings.checkoutSettings?.googleAnalyticsMeasurementId}
        mode={checkoutSettings.mode}
        useTextarea
        placeholder="G-xxxxxxxxxx"
        inputValue={
          checkoutSettings.updatedCheckoutSettings?.googleAnalyticsMeasurementId
        }
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              googleAnalyticsMeasurementId: e.target.value,
            }),
          )
        }
      />

      <TextField
        title="Facebook pixel id"
        value={checkoutSettings.checkoutSettings?.facebookPixelId}
        mode={checkoutSettings.mode}
        useTextarea
        placeholder="2018098870636120"
        inputValue={checkoutSettings.updatedCheckoutSettings?.facebookPixelId}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              facebookPixelId: e.target.value,
            }),
          )
        }
      />

      <TextField
        title="Facebook conversions api token"
        value={checkoutSettings.checkoutSettings?.facebookConversionsAPIToken}
        mode={checkoutSettings.mode}
        useTextarea
        placeholder="token"
        inputValue={checkoutSettings.updatedCheckoutSettings?.facebookConversionsAPIToken}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              facebookConversionsAPIToken: e.target.value,
            }),
          )
        }
      />

      <TextField
        title="Scripts"
        value={checkoutSettings.checkoutSettings?.scripts}
        mode={checkoutSettings.mode}
        useTextarea
        placeholder="<script>console.log('script 1')</script>, <script>console.log('script 2')</script>"
        inputValue={checkoutSettings.updatedCheckoutSettings?.scripts}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutSettings({
              scripts: e.target.value,
            }),
          )
        }
      />
      {integration.type === 'shopify' && (
        <Fragment>
          <TextField
            title="Vendor local pickup rate title"
            value={checkoutSettings.checkoutSettings?.vendorLocalPickupRateTitle}
            mode={checkoutSettings.mode}
            placeholder="Vendor local pickup rate title"
            inputValue={
              checkoutSettings.updatedCheckoutSettings?.vendorLocalPickupRateTitle
            }
            onChange={(e) =>
              dispatch(
                storeSlice.actions.setUpdatedCheckoutSettings({
                  vendorLocalPickupRateTitle: e.target.value,
                }),
              )
            }
          />
          <TextField
            title="Local pickup rate description"
            value={checkoutSettings.checkoutSettings?.localPickupRateDescription}
            mode={checkoutSettings.mode}
            placeholder="Local pickup rate description"
            inputValue={
              checkoutSettings.updatedCheckoutSettings?.localPickupRateDescription
            }
            onChange={(e) =>
              dispatch(
                storeSlice.actions.setUpdatedCheckoutSettings({
                  localPickupRateDescription: e.target.value,
                }),
              )
            }
          />
        </Fragment>
      )}
    </Card>
  )
}
