import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    margin: 32px 0;
  `,

  text: css`
    margin: 0 0 16px 0;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${Colors.DARK_1};
  `,
}
