import { Integration } from '@softcery/qc-apiclient'
import { createStore, createEvent, createEffect, restore, combine } from 'effector'
import { createGate } from 'effector-react'
import { getIntegrationFx, IntegrationGate } from '~/entities/integration'
import { apiService, getErrorMessage } from '~/shared/api'

interface CreateOptions {
  storeId: string
  fields: Integration
}

export const createFx = createEffect(async (requestOptions: CreateOptions) => {
  try {
    return await apiService().createIntegration(requestOptions)
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to create integration`, errorMessage)
    return Promise.reject(errorMessage)
  }
})

export const Gate = createGate()

export const setFields = createEvent<Partial<Integration>>()
export const $fields = createStore<Integration>({})
  .on(setFields, (state, payload) => ({ ...state, ...payload }))
  .on([getIntegrationFx.doneData, createFx.doneData], (_, payload) => payload.integration)
  .reset([Gate.close, IntegrationGate.close])

getIntegrationFx.fail.watch((data) => {
  if (data.error.status === 404) {
    $fields.reset()
  }
})

export const $createStatus = combine({
  loading: createFx.pending,
  error: restore(createFx.failData, null).reset(Gate.close),
})
