import { createGate } from 'effector-react'
import { combine, createEffect, createEvent, createStore, restore } from 'effector'
import { Merchant, CreateMerchantOperationRequest } from '@softcery/qc-apiclient'
import { apiService, getErrorMessage } from '~/shared/api'

export const createFx = createEffect(
  async (requestOptions: CreateMerchantOperationRequest) => {
    try {
      return await apiService().createMerchant(requestOptions)
    } catch (res: any) {
      const errorMessage = await getErrorMessage(res)
      console.error(`failed to create merchant`, errorMessage)
      return Promise.reject(errorMessage)
    }
  },
)

export const Gate = createGate()

export const setFields = createEvent<Partial<Merchant>>()

export const $fields = createStore<Merchant>({ name: '' })
  .on(setFields, (state, payload) => ({ ...state, ...payload }))
  .reset([createFx.done, Gate.close])

export const $createStatus = combine({
  loading: createFx.pending,
  error: restore(createFx.failData, null),
})
