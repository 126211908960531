import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;

    white-space: pre-wrap;
    word-break: break-word;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: ${Colors.DARK_7};

    &:last-of-type {
      margin-bottom: 0;
    }
  `,

  title: {
    base: css`
      margin-bottom: 2px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: ${Colors.DARK_1};
    `,

    required: css`
      color: ${Colors.DARK_8};
    `,

    displaying: css`
      margin-bottom: 8px;
    `,

    tip: css`
      font-weight: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-decoration: underline;
      color: ${Colors.DARK_8};

      cursor: pointer;
    `,
  },

  select: {
    container: css`
      position: relative;
      width: 100%;

      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
    `,

    value: {
      base: css`
        padding: 8px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 14px;
        line-height: 17px;
        color: ${Colors.DARK_7};

        background: ${Colors.LIGHT_10};
        border-radius: 5px;

        cursor: pointer;
      `,

      placeholder: css`
        color: ${Colors.DARK_3};
        user-select: none;
      `,
    },

    options: {
      container: css`
        width: 100%;
        position: absolute;
        top: 120%;
        left: 0;
        z-index: 1;

        box-sizing: border-box;
        padding: 8px;

        box-shadow: 0 0 5px 1px ${Colors.LIGHT_11};
        background: ${Colors.LIGHT_1};
        border-radius: 3px;
      `,

      item: css`
        padding: 8px;

        font-size: 12px;
        line-height: 10px;
        color: ${Colors.DARK_2};

        cursor: pointer;

        &:hover {
          background: #eff1f5;
          border-radius: 3px;
        }
      `,
    },
  },

  disabled: css`
    opacity: 0.6;
    pointer-events: none;
  `,
}
