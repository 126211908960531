import { useEffect, Fragment, FC } from 'react'
import { push } from 'connected-react-router'
import { Merchant } from '@softcery/qc-apiclient'
import { useStore } from 'effector-react'
import { useAppDispatch } from '~/shared/model'
import { Button, Loader } from '~/shared/ui/atoms'
import { Toolbar, Pagination } from '~/shared/ui/molecules'
import { Table } from '~/shared/ui/organisms'
import {
  getMerchants,
  $merchants,
  $getMerchantsStatus,
  $merchantsRequestOptions,
  $totalMerchants,
  setMerchantsRequestOptions,
} from '~/entities/merchants'
import { setDeleteMerchantFields } from '~/features/merchant/delete-merchant'
import { SearchMerchantsInput } from '~/features/search-merchants'
import { styles } from './styles'

interface Props {
  onCreateMerchant: () => void
  showDeleteMerchantPopup: () => void
}

export const MerchantsTable: FC<Props> = ({
  onCreateMerchant,
  showDeleteMerchantPopup,
}) => {
  const dispatch = useAppDispatch()
  const merchants = useStore($merchants)
  const { error, loading } = useStore($getMerchantsStatus)
  const merchantsRequestOptions = useStore($merchantsRequestOptions)
  const totalMerchants = useStore($totalMerchants)

  const onSetPagination = (offset?: number, limit?: number) =>
    setMerchantsRequestOptions({ offset, limit })

  const onMerchantVisit = (id: string) => () => dispatch(push(`/merchant/${id}`))

  const onMerchantDelete = (merchant: Merchant) => () => {
    setDeleteMerchantFields(merchant)
    showDeleteMerchantPopup()
  }

  // get merchants when request options changed
  useEffect(() => {
    // make request when search do not contain only spaces, or it is empty
    if (
      merchantsRequestOptions.search?.trim().length ||
      !merchantsRequestOptions.search
    ) {
      getMerchants(merchantsRequestOptions)
    }
  }, [merchantsRequestOptions])

  return (
    <Table
      loading={loading}
      error={error?.message || ''}
      toolbar={
        <Toolbar
          extendStyles={{
            container: styles.toolbar.container,
            title: { text: styles.toolbar.title.text },
          }}
          titleIconBefore={
            <img src={require('./assets/merchants.svg')} alt="merchants" />
          }
          title="MERCHANTS"
          right={
            <Fragment>
              <div css={styles.toolbar.loaderContainer}>
                {loading && <Loader type="dark" />}
              </div>
              <Button
                extendStyle={styles.toolbar.createButton}
                onClick={onCreateMerchant}
              >
                CREATE
              </Button>
              <SearchMerchantsInput />
              {totalMerchants !== 0 && (
                <Pagination
                  loading={loading}
                  total={totalMerchants}
                  pagination={merchantsRequestOptions}
                  setPagination={onSetPagination}
                />
              )}
            </Fragment>
          }
        />
      }
      extendStyles={{ tableHeaderContainer: styles.tableHeaderContainer }}
      tableHeads={['Name', 'Email', 'Website', 'Memo']}
      tableRows={merchants.map((merchant) => (
        <div css={styles.tableRow.container} key={merchant.id}>
          <span>{merchant.name}</span>
          <span>{merchant.email || '-'}</span>
          <span>{merchant.website || '-'}</span>
          <span>{merchant.memoText || '-'}</span>
          <div css={styles.merchantActions.container}>
            <div
              css={styles.merchantActions.action.base}
              id="action"
              onClick={onMerchantVisit(merchant.id || '')}
            >
              <img src={require('./assets/external-link.svg')} alt="external link" />
            </div>
            <div
              css={styles.merchantActions.action.base}
              id="action"
              onClick={onMerchantDelete(merchant)}
            >
              <img src={require('./assets/trash.svg')} alt="trash" />
            </div>

            <div
              css={[
                styles.merchantActions.action.base,
                styles.merchantActions.action.actionIcon,
              ]}
              id="options-icon"
            >
              <img
                src={require('./assets/options.svg')}
                alt="options"
                id="options-icon"
              />
            </div>
          </div>
        </div>
      ))}
      noItemsMessage="No merchants found"
    />
  )
}
