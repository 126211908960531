import { useState, FC, Fragment } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { WithSidebar } from '~/shared/ui/layout'
import { Sidebar } from '~/widgets/sidebar'
import { MerchantHeader } from '~/widgets/merchant-header'

import { Dashboard } from './dashboard'
import { Store } from './store'

export const Merchant: FC = () => {
  const { url } = useRouteMatch()

  const [showStoreDeletePopup, setShowStoreDeletePopup] = useState<boolean>(false)

  return (
    <WithSidebar
      sidebar={<Sidebar />}
      content={
        <Fragment>
          <MerchantHeader onDeleteClick={() => setShowStoreDeletePopup(true)} />
          <Switch>
            {/* TODO: fix redirects */}
            <Route
              path={`${url}/:merchantId/store/:storeId`}
              render={() => (
                <Store
                  showDeletePopup={showStoreDeletePopup}
                  setShowDeletePopup={setShowStoreDeletePopup}
                />
              )}
            />

            <Route path={`${url}/:merchantId`} component={Dashboard} />
          </Switch>
        </Fragment>
      }
    />
  )
}
