import { ButtonHTMLAttributes, FC } from 'react'
import { SerializedStyles } from '@emotion/react'

import { styles } from './styles'

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  dataTestId?: string
  extendStyle?: SerializedStyles
}

// Button provides a universal button.
// Used on Login, Merchants, Create merchant screens
export const Button: FC<Props> = ({
  dataTestId,
  extendStyle,
  onClick,
  children,
  ...props
}) => {
  return (
    <button
      css={[styles.button, extendStyle]}
      type="button"
      onClick={onClick}
      data-testid={dataTestId}
      tabIndex={0}
      {...props}
    >
      <span>{children}</span>
    </button>
  )
}
