import { ChangeEvent, FC } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Modes } from '~/types'
import { styles } from './styles'

interface TextFieldProps {
  mode: Modes
  title: string
  value?: string
  useTextarea?: boolean
  placeholder: string
  required?: boolean
  disabled?: boolean
  useColorInput?: boolean

  inputValue?: string
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
}

// TextField provides a text or input depending on the mode.
// Used on Merchant, Store screens.
export const TextField: FC<TextFieldProps> = ({
  mode,
  title,
  value,
  useTextarea,
  placeholder,
  required,
  disabled,
  useColorInput,

  inputValue,
  onChange,
}) => {
  return (
    <div css={[styles.container.base, disabled && styles.container.disabled]}>
      <span
        css={[styles.title.base, mode === Modes.Displaying && styles.title.displaying]}
      >
        {title}
        {mode === Modes.Editing && required && (
          <span css={styles.title.required}> *</span>
        )}
      </span>
      <div css={styles.inputContainer(mode === Modes.Editing)}>
        {useColorInput && (
          <div css={styles.colorInputContainer(inputValue || value || '')}>
            <input
              type="color"
              defaultValue={value}
              value={inputValue}
              disabled={mode === Modes.Displaying}
              onChange={onChange}
              css={styles.colorInput(mode === Modes.Displaying)}
            />
          </div>
        )}
        {mode === Modes.Displaying ? (
          <span>{value || '–'}</span>
        ) : useTextarea ? (
          <TextareaAutosize
            css={[styles.input, styles.textarea]}
            maxRows={5}
            maxLength={1000}
            value={inputValue}
            onChange={onChange}
            placeholder={placeholder}
          />
        ) : (
          <input
            css={styles.input}
            value={inputValue}
            onChange={onChange}
            placeholder={placeholder}
          />
        )}
      </div>
    </div>
  )
}
