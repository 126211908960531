import { FC, Fragment } from 'react'
import { useAppDispatch, useAppSelector, useClearStateOnUnmount } from '~/shared/model'
import { Button, Error, H2 } from '~/shared/ui/atoms'
import { ButtonWithLoader, Popup as BasePopup } from '~/shared/ui/molecules'
import { styles } from './styles'
import { deleteStore, deleteStoreSlice } from '../../..'

interface Props {
  onDeleteComplete: () => void
  onClose: () => void
}

export const Popup: FC<Props> = ({ onClose, onDeleteComplete }) => {
  const dispatch = useAppDispatch()
  const { store } = useAppSelector((s) => s.store)
  const ownerMerchantId = useAppSelector((s) => s.merchantDashboard.merchant.merchant?.id)

  useClearStateOnUnmount(deleteStoreSlice.actions.clearState)

  const onDelete = () =>
    dispatch(deleteStore({ storeId: store.store?.id, ownerMerchantId })).then(
      (action) => {
        if (action.meta.requestStatus === 'fulfilled') {
          onDeleteComplete()
        }
      },
    )

  return (
    <BasePopup
      title={<H2>Delete “{store.store?.name}”</H2>}
      content={
        <Fragment>
          <span
            css={styles.text}
          >{`Are you sure you want to delete “${store.store?.name}”? All data of this store will be deleted, including gateway, integration, and orders.`}</span>

          {store.error && <Error extendStyle={styles.error}>{store.error}</Error>}
        </Fragment>
      }
      footer={
        <div css={styles.buttons}>
          <ButtonWithLoader
            loading={store.loading}
            disabled={store.loading}
            onClick={onDelete}
          >
            DELETE STORE
          </ButtonWithLoader>
          <Button disabled={store.loading} extendStyle={styles.cancel} onClick={onClose}>
            CANCEL
          </Button>
        </div>
      }
      onClose={onClose}
    />
  )
}
