import { Action } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useAppDispatch } from '.'

// useClearStateOnUnmount provides a hook
// that dispatch clear action when screen unmounted
export const useClearStateOnUnmount = (clearAction: Action) => {
  const dispatch = useAppDispatch()

  // clear redux state when screen unmounted
  useEffect(
    () => () => {
      dispatch(clearAction)
    },
    [],
  )
}
