import { css } from '@emotion/react'

export const styles = {
  content: css`
    margin: 32px 0;
  `,

  inputContainer: css`
    margin-bottom: 16px;
  `,

  label: css`
    margin-bottom: 8px;
  `,

  error: css`
    margin-top: 18px;
  `,
}
