import { Integration } from '@softcery/qc-apiclient'
import {
  createStore,
  createEvent,
  createEffect,
  restore,
  combine,
  sample,
} from 'effector'
import { apiService, getErrorMessage } from '~/shared/api'

interface UpdateOptions {
  storeId: string
  fields: Integration
}

export const updateFx = createEffect(async (requestOptions: UpdateOptions) => {
  try {
    return await apiService().updateIntegration(requestOptions)
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to update integration`, errorMessage)
    return Promise.reject(errorMessage)
  }
})

export const setFields = createEvent<Partial<Integration>>()
export const $fields = createStore<Integration>({})
  .on(setFields, (state, payload) => ({ ...state, ...payload }))
  .on(updateFx.doneData, (_, payload) => payload.integration)

export const update = createEvent<{ storeId: string }>()

sample({
  clock: update,
  source: $fields,
  fn: (fields, payload) =>
    ({
      storeId: payload.storeId,
      fields,
    } as UpdateOptions),
  target: updateFx,
})

export const $updateStatus = combine({
  loading: updateFx.pending,
  error: restore(updateFx.failData, null),
})
