import {
  BigcommerceConfiguration,
  UpdateBigcommerceConfigurationRequest,
} from '@softcery/qc-apiclient'
import {
  createStore,
  createEvent,
  createEffect,
  restore,
  combine,
  sample,
} from 'effector'
import { apiService, getErrorMessage } from '~/shared/api'

const updateFx = createEffect(
  async (requestOptions: UpdateBigcommerceConfigurationRequest) => {
    try {
      return await apiService().updateBigcommerceConfiguration(requestOptions)
    } catch (res: any) {
      const errorMessage = await getErrorMessage(res)
      console.error(`failed to update bigcommerce configuration`, errorMessage)
      return Promise.reject(errorMessage)
    }
  },
)

export const setFields = createEvent<Partial<BigcommerceConfiguration>>()
export const $fields = createStore<BigcommerceConfiguration>({})
  .on(setFields, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(updateFx.doneData, (_, payload) => payload._configuration)

export const update = createEvent()

sample({
  clock: update,
  source: $fields,
  fn: (fields) =>
    ({
      fields,
    } as UpdateBigcommerceConfigurationRequest),
  target: updateFx,
})

export const $updateStatus = combine({
  loading: updateFx.pending,
  error: restore(updateFx.failData, null),
})
