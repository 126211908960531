import { FC, useContext, useEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '~/shared/model'
import { ApiContext } from '~/app/hoc/with-api'
import { Card, TextField, CardHeader } from '~/components'
import { Modes, CardHeaderTypes } from '~/types'

import { styles } from '../../styles'
import { StoreActions, storeSlice } from '../../redux'
import { MatchParams } from '../../types'

export const Info: FC = () => {
  // get state and actions
  const dispatch = useAppDispatch()
  const { store } = useAppSelector((t) => t.store)

  // initialize actions
  const { api } = useContext(ApiContext)
  const storeActions = useMemo(() => new StoreActions(api), [api])

  // get route params
  const { params } = useRouteMatch<MatchParams>()

  // get integration, set mode to displaying
  useEffect(() => {
    // get store
    dispatch(storeActions.getStore(params.storeId))

    // set mode to displaying
    dispatch(storeSlice.actions.setStoreMode(Modes.Displaying))
  }, [])

  // start store updating
  const editStore = (): void => {
    // set updated store
    dispatch(storeSlice.actions.setUpdatedStore(store.store))

    // change mode
    dispatch(storeSlice.actions.setStoreMode(Modes.Editing))
  }

  // cancel store editing
  const cancelStoreEditing = (): void => {
    // set updated store to store from api
    dispatch(storeSlice.actions.setUpdatedStore(store.store))

    // set mode to displaying
    dispatch(storeSlice.actions.setStoreMode(Modes.Displaying))
  }

  // save edited store
  const saveStore = (): void => {
    // update store request
    dispatch(
      storeActions.updateStore({
        storeId: params.storeId,
        store: store.updatedStore!,
      }),
    )
  }

  return (
    <Card
      loading={store.loading && store.mode === Modes.Displaying}
      error={store.error}
      showContent={
        store.mode === Modes.Editing || (store.mode === Modes.Displaying && !store.error)
      }
      extendStyle={{ body: styles.cardBody }}
      header={
        <CardHeader
          mode={store.mode}
          type={CardHeaderTypes.Edit}
          title="INFO"
          onEdit={editStore}
          disableEditButton={Boolean(store.error)}
          onSave={saveStore}
          disableSaveButton={
            store.loading || !store.updatedStore?.name || Boolean(store.error)
          }
          onDiscard={cancelStoreEditing}
        />
      }
    >
      <TextField
        title="Name"
        value={store.store?.name}
        mode={store.mode}
        placeholder="Super Shop"
        required
        inputValue={store.updatedStore?.name}
        onChange={(e) =>
          dispatch(storeSlice.actions.setUpdatedStore({ name: e.target.value }))
        }
      />
      <TextField
        title="Website"
        value={store.store?.website}
        mode={store.mode}
        placeholder="https://example.com"
        inputValue={store.updatedStore?.website}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedStore({
              website: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Note"
        mode={store.mode}
        useTextarea
        value={store.store?.memoText}
        placeholder="Any additional info"
        inputValue={store.updatedStore?.memoText}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedStore({
              memoText: e.target.value,
            }),
          )
        }
      />
    </Card>
  )
}
