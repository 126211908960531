import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    position: relative;
    height: 49px;

    box-sizing: border-box;
    padding: 18px 16px;
    margin-bottom: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${Colors.DARK_7};

    background: ${Colors.LIGHT_10};
    border-radius: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,

  info: css`
    margin-right: 10px;

    display: flex;
    align-items: center;

    overflow: hidden;

    img {
      margin-right: 12px;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `,

  statusIcon: css`
    &:hover {
      // show span after status icon
      & + span {
        display: block;
      }
    }
  `,

  tooltip: css`
    display: none;
    position: absolute;
    left: 98%;

    padding: 8px;

    white-space: nowrap;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    color: ${Colors.DARK_1};

    background: ${Colors.LIGHT_1};
    box-shadow: 0px 15px 25px ${Colors.LIGHT_13};
    border-radius: 5px;
  `,
}
