import { FC, useState } from 'react'
import { push } from 'connected-react-router'
import { useStore } from 'effector-react'
import { useAppDispatch } from '~/shared/model'
import { ErrorPopup } from '~/components'
import { WithSidebar } from '~/shared/ui/layout'
import {
  $getMerchantsStatus,
  setMerchantsRequestOptions,
  clearMerchantsError,
} from '~/entities/merchants'
import { CreateMerchantPopup } from '~/features/merchant/create-merchant'
import { DeleteMerchantPopup } from '~/features/merchant/delete-merchant'
import { MerchantsTable } from '~/widgets/merchants-table'
import { Sidebar } from '~/widgets/sidebar'
import { styles } from './styles'

export const Merchants: FC = () => {
  const dispatch = useAppDispatch()

  const { error } = useStore($getMerchantsStatus)

  // state for delete popup
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
  // state for create popup
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false)

  return (
    <WithSidebar
      sidebar={<Sidebar />}
      content={
        <div css={styles.container}>
          {error?.message && (
            <ErrorPopup
              error={error.message}
              reload={() => setMerchantsRequestOptions({})}
              closePopup={() => clearMerchantsError()}
            />
          )}
          {showDeletePopup && (
            <DeleteMerchantPopup onClose={() => setShowDeletePopup(false)} />
          )}
          {showCreatePopup && (
            <CreateMerchantPopup
              onClose={() => setShowCreatePopup(false)}
              onCreateComplete={(merchantId) => dispatch(push(`/merchant/${merchantId}`))}
            />
          )}
          <MerchantsTable
            onCreateMerchant={() => setShowCreatePopup(true)}
            showDeleteMerchantPopup={() => setShowDeletePopup(true)}
          />
        </div>
      }
    />
  )
}
