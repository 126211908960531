import { combine, createEffect, createStore, forward, restore } from 'effector'
import { createGate } from 'effector-react'
import { Store } from '@softcery/qc-apiclient'
import { apiService, getErrorMessage } from '~/shared/api'

export const getStoresFx = createEffect(async (ownerMerchantId: string) => {
  try {
    return await apiService().getStores({ ownerMerchantId, extend: ['status'] })
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to get stores`, errorMessage)
    return Promise.reject(errorMessage)
  }
})

export const $stores = createStore<Store[]>([]).on(
  getStoresFx.doneData,
  (_, payload) => payload.stores,
)

export const $getStoresStatus = combine({
  loading: getStoresFx.pending,
  error: restore(getStoresFx.failData, null),
})

export const StoresGate = createGate<string>()

forward({
  from: StoresGate.open,
  to: getStoresFx,
})
