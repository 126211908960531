import { FC, useEffect, MouseEvent, ReactNode } from 'react'
import { SerializedStyles } from '@emotion/react'
import { styles } from './styles'

interface Props {
  title: ReactNode
  loading?: boolean
  content?: ReactNode
  footer?: ReactNode
  extendStyle?: {
    container?: SerializedStyles
    popup?: SerializedStyles
    content?: SerializedStyles
  }

  onClose?: () => void
}

export const Popup: FC<Props> = ({
  title,
  loading,
  content,
  footer,
  extendStyle,

  onClose,
}) => {
  // prevent scrolling of body
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [])

  return (
    <div
      css={[styles.container, extendStyle?.container]}
      id="popup-container"
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        // to close popup, when click on free space
        if ((e.target as HTMLDivElement).id === 'popup-container' && onClose) {
          onClose()
        }
      }}
    >
      <div css={[styles.popup, extendStyle?.popup]}>
        <div css={styles.header.container}>
          {title}

          {onClose && (
            <img
              src={require('./assets/close.svg')}
              alt="close"
              onClick={onClose}
              css={[
                styles.header.closeIcon.base,
                loading && styles.header.closeIcon.disable,
              ]}
            />
          )}
        </div>
        <div css={[styles.content, extendStyle?.content]}>{content}</div>
        {footer}
      </div>
    </div>
  )
}
