import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  cardHeaderTitle: css`
    display: flex;
    align-items: center;

    & > img {
      margin-left: 8px;
    }
  `,

  status: {
    statusContainer: css`
      margin-bottom: 16px;
    `,

    pending: css`
      color: ${Colors.WARNING_1};

      background: ${Colors.WARNING_2};
      border: 1px solid ${Colors.WARNING_3};
    `,
  },

  statusIcon: css`
    margin-right: 10px;
  `,

  cardBody: css`
    min-height: 187px;
  `,
}
