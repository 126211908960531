import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { push } from 'connected-react-router'

import { useAppDispatch, useAppSelector } from '~/shared/model'
import { ApiContext } from '~/app/hoc/with-api'
import { Card, TextField, CardHeader } from '~/components'
import { StoresCard } from '~/entities/stores'
import { CreateStorePopup } from '~/features/store/create-store'
import { Modes, CardHeaderTypes } from '~/types'

import { styles } from './styles'
import { MerchantDashboardActions, merchantDashboardSlice } from './redux'

export const Dashboard: FC = () => {
  // get state and actions
  const dispatch = useAppDispatch()
  const { merchant } = useAppSelector((t) => t.merchantDashboard)

  const [showCreateStorePopup, setShowCreateStorePopup] = useState<boolean>(false)

  // initialize actions
  const { api } = useContext(ApiContext)
  const merchantActions = useMemo(() => new MerchantDashboardActions(api), [api])

  // get url and route params
  const { params } = useRouteMatch<{ merchantId: string }>()

  // get merchant, set mode to displaying
  useEffect(() => {
    // get merchant
    dispatch(merchantActions.getMerchant(params.merchantId))

    // set mode to displaying
    dispatch(merchantDashboardSlice.actions.setMerchantMode(Modes.Displaying))
  }, [])

  // start merchant updating
  const editMerchant = (): void => {
    // set updated merchant
    dispatch(merchantDashboardSlice.actions.setUpdatedMerchant(merchant.merchant))

    // change mode
    dispatch(merchantDashboardSlice.actions.setMerchantMode(Modes.Editing))
  }

  // save updated merchant
  const saveMerchant = (): void => {
    // update merchant request
    dispatch(
      merchantActions.updateMerchant({
        merchantId: params.merchantId,
        merchant: merchant.updatedMerchant!,
      }),
    )
  }

  // cancel merchant updating
  const cancelMerchantEditing = (): void => {
    // set updated merchant to merchant from api
    dispatch(merchantDashboardSlice.actions.setUpdatedMerchant(merchant.merchant))

    // set mode to displaying
    dispatch(merchantDashboardSlice.actions.setMerchantMode(Modes.Displaying))
  }

  return (
    <Fragment>
      {showCreateStorePopup && (
        <CreateStorePopup
          ownerMerchantId={params.merchantId}
          onCreateComplete={(ownerMerchantId, storeId) =>
            dispatch(push(`/merchant/${ownerMerchantId}/store/${storeId}`))
          }
          onClose={() => setShowCreateStorePopup(false)}
        />
      )}
      <div css={styles.container}>
        <div css={styles.cards}>
          {/* Merchant info */}
          <Card
            loading={merchant.loading && merchant.mode === Modes.Displaying}
            error={merchant.error}
            showContent={
              merchant.mode === Modes.Editing ||
              (merchant.mode === Modes.Displaying && !merchant.error)
            }
            header={
              <CardHeader
                mode={merchant.mode}
                type={CardHeaderTypes.Edit}
                title="INFO"
                onEdit={editMerchant}
                disableEditButton={Boolean(merchant.error)}
                onSave={saveMerchant}
                disableSaveButton={
                  merchant.loading ||
                  !merchant.updatedMerchant?.name ||
                  Boolean(merchant.error)
                }
                onDiscard={cancelMerchantEditing}
              />
            }
          >
            <TextField
              title="Name"
              value={merchant.merchant?.name}
              mode={merchant.mode}
              placeholder="John Doe"
              required
              inputValue={merchant.updatedMerchant?.name}
              onChange={(e) =>
                dispatch(
                  merchantDashboardSlice.actions.setUpdatedMerchant({
                    name: e.target.value,
                  }),
                )
              }
            />
            <TextField
              title="Email"
              value={merchant.merchant?.email}
              mode={merchant.mode}
              placeholder="john.doe@gmail.cpm"
              inputValue={merchant.updatedMerchant?.email}
              onChange={(e) =>
                dispatch(
                  merchantDashboardSlice.actions.setUpdatedMerchant({
                    email: e.target.value,
                  }),
                )
              }
            />
            <TextField
              title="Website"
              value={merchant.merchant?.website}
              mode={merchant.mode}
              placeholder="https://example.com"
              inputValue={merchant.updatedMerchant?.website}
              onChange={(e) =>
                dispatch(
                  merchantDashboardSlice.actions.setUpdatedMerchant({
                    website: e.target.value,
                  }),
                )
              }
            />
            <TextField
              title="Note"
              mode={merchant.mode}
              useTextarea
              value={merchant.merchant?.memoText}
              placeholder="Any additional info"
              inputValue={merchant.updatedMerchant?.memoText}
              onChange={(e) =>
                dispatch(
                  merchantDashboardSlice.actions.setUpdatedMerchant({
                    memoText: e.target.value,
                  }),
                )
              }
            />
          </Card>
          <StoresCard
            setShowCreateStorePopup={setShowCreateStorePopup}
            ownerMerchantId={params.merchantId}
          />
        </div>
      </div>
    </Fragment>
  )
}
