import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    padding: 32px;
  `,

  cards: css`
    width: 100%;
    display: flex;

    margin-left: -12px;

    & > div {
      margin-left: 24px;
    }

    & > div > div {
      margin-bottom: 24px;
    }
  `,

  cardBody: css`
    min-height: 187px;
  `,

  cardHeaderTitle: css`
    display: flex;
    align-items: center;

    & > img {
      margin-left: 8px;
    }
  `,

  status: {
    active: css`
      color: ${Colors.SUCCESS_2};

      background: ${Colors.SUCCESS_3};
      border: 1px solid ${Colors.SUCCESS_4};
    `,

    pending: css`
      color: ${Colors.WARNING_1};

      background: ${Colors.WARNING_2};
      border: 1px solid ${Colors.WARNING_3};
    `,

    failed: css`
      color: ${Colors.ERROR_1};

      background: ${Colors.ERROR_2};
      border: 1px solid ${Colors.ERROR_3};
    `,
  },

  statusIcon: css`
    margin-right: 10px;
  `,
}
