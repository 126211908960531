import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  toolbar: {
    container: css`
      background: ${Colors.LIGHT_1};
      padding: 8px 16px;
      border-radius: 7px 7px 0 0;
      border-bottom: 1px solid ${Colors.LIGHT_11};
      height: 50px;
    `,
    title: {
      text: css`
        font-weight: 600;
        font-size: 14px;
      `,
    },
  },

  loaderContainer: css`
    margin-right: 24px;
  `,

  tableGridColumnWidth: (columns: number) => css`
    grid-template-columns: repeat(${columns}, 1fr);
  `,
}
