import { FC } from 'react'
import { SerializedStyles } from '@emotion/react'
import { styles } from './styles'

interface Props {
  extendStyle?: SerializedStyles
}

export const Label: FC<Props> = ({ extendStyle, children }) => {
  return <label css={[styles.base, extendStyle]}>{children}</label>
}
