import { FC } from 'react'
import { styles } from './styles'

interface Props {
  type: 'dark' | 'light'
}

// Loader provides a dark and light loader, that can be showed when some action in progress.
// Used on Login, Merchants, Create merchant screens.
export const Loader: FC<Props> = ({ type }) => {
  return (
    <img
      src={
        type === 'dark'
          ? require('./assets/dark-loader.svg')
          : require('./assets/light-loader.svg')
      }
      alt="loader"
      css={styles.loader}
    />
  )
}
