import React, { FC, Fragment, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useStore, useGate } from 'effector-react'
import { Integration } from '@softcery/qc-apiclient'

// TODO: move it into shared/
import { IntegrationStatus } from '~/components'
import { IntegrationStatuses } from '~/types'
import { getIntegrationStatus } from '~/shared/lib/getIntegrationStatus'
import { Error, H2, Button, Label } from '~/shared/ui/atoms'
import {
  Popup as BasePopup,
  InputWithLabel,
  ButtonWithLoader,
} from '~/shared/ui/molecules'

import { styles } from './styles'
import {
  $createIntegrationFields,
  setCreateIntegrationFields,
  $createIntegrationStatus,
  createIntegrationFx,
  CreateIntegrationGate,
  Platforms,
} from '../../..'
import {
  deleteIntegrationFx,
  getIntegrationFx,
  setIntegration,
} from '~/entities/integration'
import { PLATFORM_OPTIONS } from '~/constants'

interface Props {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>
  setShowInstruction: React.Dispatch<React.SetStateAction<boolean>>
  vendorType: Platforms
}

export const Popup: FC<Props> = ({ setShowPopup, setShowInstruction, vendorType }) => {
  const fields = useStore($createIntegrationFields)
  const { loading, error } = useStore($createIntegrationStatus)

  const handleChange =
    (key: keyof Integration) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setCreateIntegrationFields({ [key]: event.target.value })

  const onCreate = () => createIntegrationFx({ storeId: params.storeId, fields })

  // get route params
  const { params } = useRouteMatch<{
    merchantId: string
    storeId: string
  }>()

  useGate(CreateIntegrationGate)

  useEffect(() => {
    // get integration every 3 seconds, after creating
    // if status exist - set interval
    let intervalId: NodeJS.Timeout
    if (fields.status && !fields.isUsedPublicApp) {
      intervalId = setInterval(() => {
        // check integration status
        if (fields.status !== IntegrationStatuses.Pending) {
          clearInterval(intervalId)
          return
        }

        // get integration
        getIntegrationFx({ storeId: params.storeId, intervalFetch: true })
      }, 3000)
    } else if (fields.status === IntegrationStatuses.Pending && fields.isUsedPublicApp) {
      setIntegration(fields)
      setShowPopup(false)
    }

    return () => clearInterval(intervalId)
  }, [fields.status])

  // close popup or delete integration and close popup
  const closePopup = (): void => {
    if (fields.status && fields.status !== IntegrationStatuses.Active && !error) {
      const isCancelled = window.confirm('Are you sure you want to cancel integration?')

      // if admin click "ok"
      if (isCancelled) {
        deleteIntegrationFx(params.storeId)
        setShowPopup(false)
      }
    } else {
      finishCreateIntegration(fields.status!)
    }
  }

  // when click "CONTINUE" button or close popup
  const finishCreateIntegration = (status: string): void => {
    setShowPopup(false)

    // reload store screen
    if (status === IntegrationStatuses.Active) {
      window.location.reload()
    }
  }

  // disable button
  const disableSubmitButton: boolean =
    Boolean(error) ||
    (vendorType === 'shopify' &&
      (!fields.vendorId || !fields.shopifyApiKey || !fields.shopifyApiSecretKey))

  return (
    <BasePopup
      title={<H2>Create Integration</H2>}
      onClose={closePopup}
      content={
        <Fragment>
          <div css={styles.platform.container}>
            <div css={styles.platform.left}>
              <Label>Platform - </Label>
              <span css={styles.platform.title}>
                {
                  PLATFORM_OPTIONS.filter((platform) => platform.value === vendorType)[0]
                    ?.title
                }
              </span>
            </div>
            <div>
              <span onClick={() => setShowInstruction(true)} css={styles.tip}>
                How to install
              </span>
            </div>
          </div>

          {vendorType === 'shopify' && (
            <Fragment>
              <InputWithLabel
                label="Shop"
                type="text"
                placeholder="example.myshopify.com"
                required
                disabled={Boolean(fields.status)}
                value={fields.vendorId}
                onChange={handleChange('vendorId')}
                extendStyles={{
                  inputContainer: styles.popup.inputContainer,
                  label: styles.popup.label,
                }}
              />
              <InputWithLabel
                label="API Key"
                type="text"
                placeholder="example-api-key"
                required
                disabled={Boolean(fields.status)}
                value={fields.shopifyApiKey}
                onChange={handleChange('shopifyApiKey')}
                extendStyles={{
                  inputContainer: styles.popup.inputContainer,
                  label: styles.popup.label,
                }}
              />
              <InputWithLabel
                label="API Secret Key"
                type="text"
                placeholder="example-api-secret-key"
                required
                disabled={Boolean(fields.status)}
                value={fields.shopifyApiSecretKey}
                onChange={handleChange('shopifyApiSecretKey')}
                extendStyles={{
                  inputContainer: styles.popup.inputContainer,
                  label: styles.popup.label,
                }}
              />
            </Fragment>
          )}

          {error && <Error extendStyle={styles.error}>{error}</Error>}
          <Fragment>
            {fields.status && (
              <IntegrationStatus
                extendStyle={styles.status[fields.status as IntegrationStatuses]}
              >
                <img
                  src={getIntegrationStatus(fields)?.icon}
                  alt="status"
                  css={styles.statusIcon}
                />
                <span>{getIntegrationStatus(fields)?.text}</span>
              </IntegrationStatus>
            )}

            {fields.status === IntegrationStatuses.Active && (
              <Button
                onClick={() => finishCreateIntegration(fields.status!)}
                extendStyle={styles.continueButton}
              >
                CONTINUE
              </Button>
            )}
          </Fragment>
        </Fragment>
      }
      footer={
        !fields.status && (
          <ButtonWithLoader
            onClick={onCreate}
            loading={loading}
            disabled={disableSubmitButton}
          >
            CREATE INTEGRATION
          </ButtonWithLoader>
        )
      }
    />
  )
}
