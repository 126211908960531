import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: {
    base: css`
      &:before {
        display: block;
        content: '';
        height: 10px;
        width: 10px;
        margin: 2px 10px 0;
        border-radius: 50%;
      }

      display: flex;
    `,

    created: css`
      &:before {
        color: ${Colors.SUCCESS_1};
        background-color: ${Colors.SUCCESS_1};
        box-shadow: 0px 0px 3px 1px ${Colors.SUCCESS_5};
      }

      color: ${Colors.SUCCESS_1};
    `,

    refunded: css`
      &:before {
        color: ${Colors.ACCENT_3};
        background-color: ${Colors.ACCENT_3};
        box-shadow: 0px 0px 3px 1px ${Colors.DARK_9};
      }

      color: ${Colors.ACCENT_3};
    `,

    partiallyRefunded: css`
      &:before {
        color: ${Colors.ACCENT_4};
        background-color: ${Colors.ACCENT_4};
        box-shadow: 0px 0px 3px 1px ${Colors.DARK_9};
      }

      color: ${Colors.ACCENT_4};
    `,

    voided: css`
      &:before {
        color: ${Colors.DARK_7};
        background-color: ${Colors.DARK_7};
        box-shadow: 0px 0px 3px 1px ${Colors.DARK_9};
      }

      color: ${Colors.DARK_7};
    `,

    error: css`
      &:before {
        display: none;
      }

      color: ${Colors.ERROR_1};
    `,
  },
}
