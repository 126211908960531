import { useEffect, useState, FC } from 'react'
import { SerializedStyles } from '@emotion/react'

import { Modes } from '~/types'
import { styles } from './styles'

interface SelectOption {
  title: string
  value: string
}

interface TextSelectProps {
  mode: Modes
  title: string
  value?: string
  staticValue?: string
  required?: boolean
  tipText?: string
  disabled?: boolean
  disableTipButton?: boolean

  placeholder?: string
  selectValue?: string
  selectOptions?: SelectOption[]
  extendStyles?: {
    select?: {
      value: {
        base: SerializedStyles
        placeholder: SerializedStyles
      }
    }
    title?: {
      base: SerializedStyles
    }
  }

  onChange?: (value: string) => any
  onTipClick?: () => void
}

// TextSelect provides a text or select depending on the mode.
// Used on Store screens.
export const TextSelect: FC<TextSelectProps> = ({
  mode,
  title,
  value,
  staticValue,
  required,
  disabled,

  placeholder,
  selectValue,
  selectOptions,
  extendStyles,

  onChange,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false)

  useEffect(() => {
    const closeOptions = (e: any) => {
      const { id } = e.target

      // close options
      if (id !== 'selectArrow' && id !== 'selectValue' && id !== 'selectValueContainer') {
        setShowOptions(false)
      }
    }

    // add event listener for window
    window.addEventListener('click', closeOptions)

    // remove event listener from window
    return () => window.removeEventListener('click', closeOptions)
  }, [])

  // selected option
  const selectedOption: SelectOption | undefined = selectOptions?.filter(
    (option) => option.value === selectValue,
  )?.[0]

  return (
    <div css={styles.container}>
      <div
        css={[
          styles.title.base,
          mode === Modes.Displaying && styles.title.displaying,
          extendStyles?.title?.base,
        ]}
      >
        {/* title */}
        <span css={disabled && styles.disabled}>
          {title}
          {mode === Modes.Editing && required && (
            <span css={styles.title.required}> *</span>
          )}
        </span>
      </div>
      {mode === Modes.Displaying ? (
        <span css={disabled && styles.disabled}>
          {selectOptions?.filter((option) => option.value === value)?.[0]?.title ||
            staticValue ||
            '–'}
        </span>
      ) : (
        <div css={[styles.select.container, disabled && styles.disabled]}>
          {/* Select value */}
          <div
            onClick={() => setShowOptions((prev) => !prev)}
            css={[
              styles.select.value.base,
              !selectedOption && styles.select.value.placeholder,
              extendStyles?.select?.value.base,
              !selectedOption && extendStyles?.select?.value.placeholder,
            ]}
            id="selectValueContainer"
          >
            <span id="selectValue">{selectedOption?.title || placeholder}</span>
            <img src={require('./assets/arrow.svg')} alt="arrow" id="selectArrow" />
          </div>

          {/* Select options */}
          {showOptions && (
            <div css={styles.select.options.container}>
              {selectOptions?.map((option) => (
                <div
                  key={option.value}
                  onClick={() => onChange?.(option.value)}
                  css={styles.select.options.item}
                >
                  {option.title}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
