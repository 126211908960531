import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    box-sizing: border-box;
    padding: 20px 16px 16px 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background: ${Colors.DARK_4};
    box-shadow: -4px 0px 16px rgba(0, 0, 0, 0.05);
  `,

  item: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  menuItem: {
    base: css`
      width: 44px;
      height: 44px;

      margin-bottom: 8px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 5px;

      &:hover {
        background: ${Colors.LIGHT_3};
      }
    `,

    active: css`
      background: ${Colors.LIGHT_3};
    `,
  },

  separator: css`
    width: 44px;
    height: 1px;

    margin: 20px 0 16px 0;

    background: ${Colors.LIGHT_4};
    border-radius: 1p;
  `,

  avatar: css`
    width: 40px;
    height: 40px;

    margin-top: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 10px;
    color: ${Colors.LIGHT_5};

    border-radius: 50%;
    background: ${Colors.LIGHT_6};
  `,
}
