import { FC } from 'react'
import { styles } from './styles'

interface StatusProps {
  value?: string
}

export const Status: FC<StatusProps> = ({ value }) => {
  const colorStyles = () => {
    switch (value) {
      case 'created':
        return styles.container.created
      case 'refunded':
        return styles.container.refunded
      case 'partially_refunded':
        return styles.container.partiallyRefunded
      case 'voided':
        return styles.container.voided
      default:
        return styles.container.error
    }
  }

  const title = () => {
    switch (value) {
      case 'created':
        return 'Created'
      case 'refunded':
        return 'Refunded'
      case 'partially_refunded':
        return 'Partially Refunded'
      case 'voided':
        return 'Voided'
      default:
        return 'Something went wrong'
    }
  }
  return <span css={[styles.container.base, colorStyles]}>{title()}</span>
}
