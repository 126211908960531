import { Store as StoreType } from '@softcery/qc-apiclient'
import { useRouteMatch, Link } from 'react-router-dom'
import { FC } from 'react'

// TODO: do not import from pages/
import { INTEGRATION_STATUSES } from '../../../../../pages/merchant/dashboard/constants'
import { Status, IntegrationStatuses } from '~/types'

import { styles } from './styles'

interface Props {
  store: StoreType
}

export const Row: FC<Props> = ({ store }) => {
  // get url
  const { url } = useRouteMatch()

  // get store integration status icon and status text
  const integrationStatus: Status = store.status
    ? INTEGRATION_STATUSES[store.status as IntegrationStatuses]
    : INTEGRATION_STATUSES.pending

  return (
    <Link css={styles.container} to={`${url}/store/${store.id}`}>
      <div css={styles.info}>
        <img src={require('./assets/store.svg')} alt="store" />
        <span>{store.name}</span>
      </div>

      <img src={integrationStatus.icon} alt="status" css={styles.statusIcon} />
      <span css={styles.tooltip}>{integrationStatus.text}</span>
    </Link>
  )
}
