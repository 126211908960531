// auth token local/session storage key
export const AUTH_TOKEN_KEY = '@qc/auth-token'
// email local/session storage key
export const AUTH_EMAIL_KEY = '@qc/auth-email'

export const ENABLE_STATUS_OPTIONS = [
  { title: 'Enabled', value: 'enabled' },
  { title: 'Disabled', value: 'disabled' },
]

// enable statuses
export enum EnableStatuses {
  Enabled = 'enabled',
  Disabled = 'disabled',
}
