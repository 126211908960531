import { SerializedStyles } from '@emotion/react'
import { FC, ReactNode } from 'react'
import { styles } from './styles'

interface Props {
  title?: string
  titleIconBefore?: ReactNode
  titleIconAfter?: ReactNode
  right?: ReactNode
  extendStyles?: {
    container?: SerializedStyles
    title?: {
      container?: SerializedStyles
      text?: SerializedStyles
    }
  }
}

export const Toolbar: FC<Props> = ({
  titleIconBefore,
  title,
  titleIconAfter,
  right,
  extendStyles,
}) => {
  return (
    <div css={[styles.container, extendStyles?.container]}>
      <div css={[styles.title.container, extendStyles?.title?.container]}>
        {titleIconBefore}
        <span css={extendStyles?.title?.text}>{title}</span>
        {titleIconAfter}
      </div>
      <div css={styles.right}>{right}</div>
    </div>
  )
}
