import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  text: css`
    display: block;
    margin: 25px 0;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${Colors.DARK_1};
  `,

  error: css`
    margin-top: 18px;
  `,

  buttons: css`
    display: flex;
    align-items: center;
  `,

  cancel: css`
    margin-left: 8px;

    color: ${Colors.DARK_7};
    background: #dcdddf;

    &:hover {
      background: ${Colors.DARK_3};
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 3px ${Colors.DARK_5};
    }
  `,
}
