import { FC } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { push } from 'connected-react-router'

import { SecondaryButton } from '~/components'
import { useAppDispatch, useAppSelector } from '~/shared/model'
import { Modes } from '~/types'

import { HeaderItem } from './ui'
import { styles } from './styles'

interface Props {
  onDeleteClick: () => any
}

export const MerchantHeader: FC<Props> = ({ onDeleteClick }) => {
  // get state and actions
  const dispatch = useAppDispatch()
  const { merchant } = useAppSelector((t) => t.merchantDashboard)
  const { store } = useAppSelector((t) => t.store)

  // get url
  const { url } = useRouteMatch()

  // get pathname
  const { pathname } = useLocation()

  return (
    <div css={styles.header}>
      <div css={styles.item}>
        <HeaderItem text="Merchants" onClick={() => dispatch(push('/merchants'))} />

        {!merchant.error && (
          <HeaderItem
            text={merchant.merchant?.name}
            loading={merchant.loading && merchant.mode === Modes.Displaying}
            showArrow
            onClick={() => dispatch(push(`${url}/${merchant.merchant?.id}`))}
          />
        )}

        {!store.error && pathname.includes('/store/') && (
          <HeaderItem
            text={store.store?.name}
            loading={store.loading && store.mode === Modes.Displaying}
            showArrow
            onClick={() =>
              dispatch(push(`${url}/${merchant.merchant?.id}/store/${store.store?.id}`))
            }
          />
        )}
      </div>

      {pathname.includes('/store/') && (
        <div css={styles.item}>
          <SecondaryButton
            extendStyle={styles.refresh}
            onClick={() => window.location.reload()}
          >
            REFRESH <img src={require('./assets/refresh.svg')} alt="refresh" />
          </SecondaryButton>
          <SecondaryButton disabled={store.loading} onClick={onDeleteClick}>
            DELETE <img src={require('./assets/delete.svg')} alt="delete" />
          </SecondaryButton>
        </div>
      )}
    </div>
  )
}
