import { FC, useContext, useEffect, useMemo, Fragment, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useStore } from 'effector-react'

import { useAppDispatch, useAppSelector } from '~/shared/model'
import { ENABLE_STATUS_OPTIONS, EnableStatuses } from '~/shared/config/constants'
import { ApiContext } from '~/app/hoc/with-api'
import { $integrationFields } from '~/entities/integration'
import { Card, TextField, CardHeader, TextSelect, IntegrationStatus } from '~/components'
import { Modes, CardHeaderTypes, Status, IntegrationStatuses } from '~/types'
import { INTEGRATION_AND_GATEWAY_STATUSES } from '~/constants'

import { styles } from '../../styles'
import { StoreActions, storeSlice } from '../../redux'
import { MatchParams } from '../../types'

export const Eye4fraudIntegration: FC = () => {
  // get state and actions
  const dispatch = useAppDispatch()
  const { eye4fraudIntegration } = useAppSelector((t) => t.store)
  const { manualTransactionCapture } = useStore($integrationFields)

  // initialize actions
  const { api } = useContext(ApiContext)
  const storeActions = useMemo(() => new StoreActions(api), [api])

  // get route params
  const { params } = useRouteMatch<MatchParams>()

  // show success status local storage key
  const SHOW_SUCCESS_STATUS = `@qc/show-success-eye4fraud-integration-status-${params.storeId}`

  // state for displaying integration status
  const [showIntegrationStatus, setShowIntegrationStatus] = useState<boolean>(false)

  // success status displays only one time
  useEffect(() => {
    if (eye4fraudIntegration.integration?.apiLogin) {
      // get from local storage
      const statusAlreadyShowed = Boolean(localStorage.getItem(SHOW_SUCCESS_STATUS))

      // set showing integration status
      setShowIntegrationStatus(!statusAlreadyShowed)

      // set value to local storage
      if (!statusAlreadyShowed) {
        localStorage.setItem(SHOW_SUCCESS_STATUS, String(true))
      }
    }
  }, [eye4fraudIntegration.integration?.apiLogin])

  // get integration, set mode to displaying
  useEffect(() => {
    // get store
    dispatch(storeActions.getEye4fraudIntegration(params.storeId))

    // set mode to displaying
    dispatch(storeSlice.actions.setEye4fraudIntegrationMode(Modes.Displaying))
  }, [])

  // start integration editing
  const editIntegration = (): void => {
    // set updated integration
    dispatch(
      storeSlice.actions.setUpdatedEye4fraudIntegration({
        ...eye4fraudIntegration.integration,
        enableStatus:
          eye4fraudIntegration.integration?.enableStatus || EnableStatuses.Disabled,
      }),
    )

    // change mode
    dispatch(storeSlice.actions.setEye4fraudIntegrationMode(Modes.Editing))
  }

  // cancel integration editing
  const cancelIntegrationEditing = (): void => {
    // set updated integration to integration from api
    dispatch(
      storeSlice.actions.setUpdatedEye4fraudIntegration(eye4fraudIntegration.integration),
    )

    // set mode to displaying
    dispatch(storeSlice.actions.setEye4fraudIntegrationMode(Modes.Displaying))
  }

  // save edited integration
  const saveIntegration = (): void => {
    // update integration request
    dispatch(
      storeActions.updateEye4fraudIntegration({
        storeId: params.storeId,
        eye4fraudIntegration: eye4fraudIntegration.updatedIntegration!,
      }),
    )
  }

  // integration status icon and text
  const integrationStatus: Status = eye4fraudIntegration.integration?.apiLogin
    ? INTEGRATION_AND_GATEWAY_STATUSES.active
    : INTEGRATION_AND_GATEWAY_STATUSES.pending

  // disable save integration button
  const disableSaveIntegration: boolean =
    eye4fraudIntegration.loading ||
    Boolean(eye4fraudIntegration.error) ||
    !eye4fraudIntegration.updatedIntegration?.enableStatus ||
    !eye4fraudIntegration.updatedIntegration?.apiLogin ||
    !eye4fraudIntegration.updatedIntegration?.apiKey

  return (
    <Card
      loading={
        eye4fraudIntegration.loading && eye4fraudIntegration.mode === Modes.Displaying
      }
      error={eye4fraudIntegration.error}
      showContent={
        eye4fraudIntegration.mode === Modes.Editing ||
        (eye4fraudIntegration.mode === Modes.Displaying && !eye4fraudIntegration.error)
      }
      extendStyle={{ body: styles.cardBody }}
      header={
        <CardHeader
          mode={eye4fraudIntegration.mode}
          type={CardHeaderTypes.Edit}
          title={
            <div css={styles.cardHeaderTitle}>
              EYE4FRAUD{' '}
              {eye4fraudIntegration.integration && (
                <img src={integrationStatus.icon} alt="status" />
              )}
            </div>
          }
          onEdit={editIntegration}
          disableEditButton={Boolean(eye4fraudIntegration.error)}
          onSave={saveIntegration}
          disableSaveButton={disableSaveIntegration}
          onDiscard={cancelIntegrationEditing}
        />
      }
    >
      <TextSelect
        title="Status"
        value={eye4fraudIntegration.integration?.enableStatus || EnableStatuses.Disabled}
        mode={eye4fraudIntegration.mode}
        placeholder="Select status"
        selectOptions={ENABLE_STATUS_OPTIONS}
        selectValue={eye4fraudIntegration.updatedIntegration?.enableStatus}
        onChange={(enableStatus) =>
          dispatch(storeSlice.actions.setUpdatedEye4fraudIntegration({ enableStatus }))
        }
      />

      {(eye4fraudIntegration.integration?.apiLogin ||
        eye4fraudIntegration.mode === Modes.Editing) && (
        <Fragment>
          <TextField
            title="ApiLogin"
            value={eye4fraudIntegration.integration?.apiLogin}
            mode={eye4fraudIntegration.mode}
            placeholder="API login provided by Eye4Fraud"
            required
            inputValue={eye4fraudIntegration.updatedIntegration?.apiLogin}
            onChange={(e) =>
              dispatch(
                storeSlice.actions.setUpdatedEye4fraudIntegration({
                  apiLogin: e.target.value,
                }),
              )
            }
          />
          <TextField
            title="ApiKey"
            value={eye4fraudIntegration.integration?.apiKey}
            mode={eye4fraudIntegration.mode}
            placeholder="API security key provided by Eye4Fraud"
            required
            inputValue={eye4fraudIntegration.updatedIntegration?.apiKey}
            onChange={(e) =>
              dispatch(
                storeSlice.actions.setUpdatedEye4fraudIntegration({
                  apiKey: e.target.value,
                }),
              )
            }
          />
          <TextSelect
            title="Automatically cancel fraud orders"
            value={eye4fraudIntegration.integration?.automaticallyCancelFraudOrders}
            mode={eye4fraudIntegration.mode}
            placeholder="Automatically cancel fraud orders"
            required
            selectOptions={ENABLE_STATUS_OPTIONS}
            selectValue={
              eye4fraudIntegration.updatedIntegration?.automaticallyCancelFraudOrders
            }
            onChange={(status) =>
              dispatch(
                storeSlice.actions.setUpdatedEye4fraudIntegration({
                  automaticallyCancelFraudOrders: status,
                }),
              )
            }
          />
          <TextSelect
            title="Automatically capture insured orders"
            value={eye4fraudIntegration.integration?.automaticallyCaptureInsuredOrders}
            mode={eye4fraudIntegration.mode}
            placeholder="Automatically capture insured orders"
            required
            selectOptions={ENABLE_STATUS_OPTIONS}
            selectValue={
              eye4fraudIntegration.updatedIntegration?.automaticallyCaptureInsuredOrders
            }
            disabled={
              eye4fraudIntegration.mode === Modes.Editing &&
              manualTransactionCapture === EnableStatuses.Disabled
            }
            onChange={(status) =>
              dispatch(
                storeSlice.actions.setUpdatedEye4fraudIntegration({
                  automaticallyCaptureInsuredOrders: status,
                }),
              )
            }
          />
        </Fragment>
      )}

      {eye4fraudIntegration.integration?.apiLogin && showIntegrationStatus && (
        <IntegrationStatus
          extendStyle={
            styles.status[
              eye4fraudIntegration.integration?.apiLogin
                ? IntegrationStatuses.Active
                : IntegrationStatuses.Pending
            ]
          }
        >
          <img src={integrationStatus.icon} alt="status" css={styles.statusIcon} />
          <span>{integrationStatus.text}</span>
        </IntegrationStatus>
      )}
    </Card>
  )
}
