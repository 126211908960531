import { Merchant } from '@softcery/qc-apiclient'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Modes } from '~/types'

// initial state of merchantSlice
const initialState = {
  merchant: {
    merchant: null as null | Merchant,
    updatedMerchant: null as null | Merchant,
    loading: true,
    error: '',
    mode: Modes.Displaying as Modes,
  },
}

// Merchant dashboard screen slice
export const merchantDashboardSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    // start getting or updating merchant
    getOrUpdateMerchant: (state) => {
      state.merchant.error = ''
      state.merchant.loading = true
    },

    // getting merchant succeeded
    getOrUpdateMerchantSuccess: (state, action: PayloadAction<Merchant | null>) => {
      state.merchant.loading = false
      state.merchant.error = ''
      state.merchant.merchant = action.payload
    },

    // getting or updating merchant failed
    getOrUpdateMerchantFailure: (state, action: PayloadAction<string>) => {
      state.merchant.loading = false
      state.merchant.error = action.payload
    },

    // set updated merchant
    setUpdatedMerchant: (state, action: PayloadAction<Partial<Merchant | null>>) => {
      state.merchant.updatedMerchant = {
        ...state.merchant.updatedMerchant,
        ...action.payload,
      } as Merchant

      // clear error
      state.merchant.error = ''
    },

    // set merchant mode
    setMerchantMode: (state, action: PayloadAction<Modes>) => {
      state.merchant.mode = action.payload

      // clear error
      state.merchant.error = ''
    },
  },
})
