import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface SecondaryButtonProps {
  disabled?: boolean
  extendStyle?: SerializedStyles

  onClick?: () => any
}

// SecondaryButton provides a universal secondary button.
// Used on Merchant, Store screens.
export const SecondaryButton: FC<SecondaryButtonProps> = ({
  disabled,
  extendStyle,
  children,

  onClick,
}) => {
  return (
    <button
      css={[styles.button.base, disabled && styles.button.disabled, extendStyle]}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
