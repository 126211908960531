import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  base: css`
    height: 35px;
    min-width: 350px;

    padding: 11px 16px;
    margin-right: 24px;

    background: ${Colors.LIGHT_9};
    border: 1px solid ${Colors.LIGHT_11};
    border-radius: 5px;

    &::placeholder {
      font-weight: normal;
      font-size: 14px;
      line-height: 11px;
    }
  `,
}
