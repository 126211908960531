import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  base: css`
    font-weight: bold;
    font-size: 24px;
    color: ${Colors.DARK_2};
    margin: 0;
  `,
}
