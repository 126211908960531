import React, { createContext } from 'react'
import { DefaultApi } from '@softcery/qc-apiclient'
import { apiService } from '~/shared/api'

type ApiContextProps = {
  api: DefaultApi
}

export const ApiContext = createContext<ApiContextProps>({
  api: undefined!,
})

// FIXME: remove this provider and rewrite to createAsyncThunk extraReducers
// withApi provides instance of api that can be used in all app components.
export const withApi = (component: () => React.ReactNode) => () => {
  return (
    <ApiContext.Provider
      value={{
        api: apiService(),
      }}
    >
      {component()}
    </ApiContext.Provider>
  )
}
