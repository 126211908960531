import { FC, useRef, useEffect, ReactNode } from 'react'
import { SerializedStyles } from '@emotion/react'
import { NoItems } from '~/shared/ui/atoms'
import { styles } from './styles'

interface Props {
  loading: boolean
  error: string
  toolbar: ReactNode
  tableHeads: string[]
  tableRows?: JSX.Element[]

  extendStyles?: {
    tableHeaderContainer?: SerializedStyles
  }
  noItemsMessage: string
}

// Table provides table component and allow to arrange date into rows and columns with pagination and additional interaction elements
export const Table: FC<Props> = ({
  loading,
  error,
  toolbar,
  tableHeads,
  tableRows,

  extendStyles,
  noItemsMessage,
}) => {
  // scroll to top of table body when get new items
  const tableBody = useRef<null | HTMLDivElement>(null)
  useEffect(() => {
    tableBody?.current?.scrollTo(0, 0)
  }, [tableRows])

  return (
    <div css={styles.table}>
      {toolbar}
      <div
        css={[
          styles.header.container,
          !tableRows && !loading && !error && styles.header.disabled,
          extendStyles?.tableHeaderContainer,
        ]}
      >
        {tableHeads.map((title) => {
          if (title !== '') {
            return <span key={title}>{title}</span>
          }
          return undefined
        })}
      </div>

      <div css={styles.tableBody} ref={tableBody}>
        {tableRows}

        {!tableRows?.length && !loading && !error && (
          <NoItems extendStyle={styles.noItems} text={noItemsMessage} />
        )}
      </div>
    </div>
  )
}
