import { useEffect, FC, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import { Popup } from '~/shared/ui/molecules'
import { API_URL } from '~/shared/config'

import { styles } from './styles'
import { Platforms } from '../../..'
import { H2 } from '~/shared/ui/atoms'

interface Props {
  vendorType: Platforms
  onClose: () => void
}

export const InstructionPopup: FC<Props> = ({ onClose, vendorType }) => {
  const [instruction, setInstruction] = useState<string>('')

  // get instruction
  useEffect(() => {
    fetch(`${API_URL}/vendors/assets/${vendorType}_instructions.md`)
      .then((res) => res.text())
      .then((instruction) => setInstruction(instruction))
  }, [])

  return (
    <Popup
      title={<H2>Integration</H2>}
      onClose={onClose}
      extendStyle={{ container: styles.popup.container, popup: styles.popup.popup }}
      content={
        <ReactMarkdown remarkPlugins={[gfm]} css={styles.instruction}>
          {instruction}
        </ReactMarkdown>
      }
    />
  )
}
