import { FC, useContext, useEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '~/shared/model'
import { ApiContext } from '~/app/hoc/with-api'
import { Card, TextField, CardHeader } from '~/components'
import { Modes, CardHeaderTypes } from '~/types'

import { styles } from '../../styles'
import { StoreActions, storeSlice } from '../../redux'
import { MatchParams } from '../../types'

export const CheckoutStyles: FC = () => {
  // get state and actions
  const dispatch = useAppDispatch()
  const { checkoutStyles } = useAppSelector((t) => t.store)

  // initialize actions
  const { api } = useContext(ApiContext)
  const storeActions = useMemo(() => new StoreActions(api), [api])

  // get route params
  const { params } = useRouteMatch<MatchParams>()

  // get checkout styles, set mode to displaying
  useEffect(() => {
    // get checkout styles
    dispatch(storeActions.getCheckoutStyles(params.storeId))

    // set mode to displaying
    dispatch(storeSlice.actions.setCheckoutStylesMode(Modes.Displaying))
  }, [])

  // start checkout styles editing
  const editCheckoutStyles = (): void => {
    // set updated checkout styles
    dispatch(storeSlice.actions.setUpdatedCheckoutStyles(checkoutStyles.checkoutStyles))

    // change mode
    dispatch(storeSlice.actions.setCheckoutStylesMode(Modes.Editing))
  }

  // cancel checkout styles editing
  const cancelCheckoutStylesEditing = (): void => {
    // set updated checkout styles to checkout styles from api
    dispatch(storeSlice.actions.setUpdatedCheckoutStyles(checkoutStyles.checkoutStyles))

    // set mode to displaying
    dispatch(storeSlice.actions.setCheckoutStylesMode(Modes.Displaying))
  }

  // save edited checkout styles
  const saveCheckoutStyles = (): void => {
    // update checkout styles request
    dispatch(
      storeActions.updateCheckoutStyles({
        storeId: params.storeId,
        checkoutStyles: checkoutStyles.updatedCheckoutStyles!,
      }),
    )
  }

  // disable save checkout styles button
  const disableSaveCheckoutStyles: boolean =
    checkoutStyles.loading ||
    Boolean(checkoutStyles.error) ||
    !checkoutStyles.updatedCheckoutStyles?.accentColor ||
    !checkoutStyles.updatedCheckoutStyles?.buttonsColor ||
    !checkoutStyles.updatedCheckoutStyles?.checkoutBackgroundColor ||
    !checkoutStyles.updatedCheckoutStyles?.summaryBackgroundColor ||
    !checkoutStyles.updatedCheckoutStyles?.summaryButtonBackgroundColor ||
    !checkoutStyles.updatedCheckoutStyles?.summaryButtonTextColor ||
    !checkoutStyles.updatedCheckoutStyles?.summaryTextColor ||
    !checkoutStyles.updatedCheckoutStyles?.textColor

  return (
    <Card
      loading={checkoutStyles.loading && checkoutStyles.mode === Modes.Displaying}
      error={checkoutStyles.error}
      showContent={
        checkoutStyles.mode === Modes.Editing ||
        (checkoutStyles.mode === Modes.Displaying && !checkoutStyles.error)
      }
      extendStyle={{ body: styles.cardBody }}
      header={
        <CardHeader
          mode={checkoutStyles.mode}
          type={CardHeaderTypes.Edit}
          title="STYLES"
          onEdit={editCheckoutStyles}
          disableEditButton={Boolean(checkoutStyles.error)}
          onSave={saveCheckoutStyles}
          disableSaveButton={disableSaveCheckoutStyles}
          onDiscard={cancelCheckoutStylesEditing}
          showResetButton
          onReset={() => {
            dispatch(
              storeSlice.actions.setUpdatedCheckoutStyles({
                accentColor: '#EF4511',
                textColor: '#151F26',
                checkoutBackgroundColor: '#FFFFFF',
                summaryBackgroundColor: '#151F26',
                summaryTextColor: '#FFFFFF',
                summaryButtonBackgroundColor: '#EFF1F5',
                summaryButtonTextColor: '#151F26',
                buttonsColor: '#151F26',
                logoUrl: '',
              }),
            )
          }}
        />
      }
    >
      <TextField
        title="Accent color"
        value={checkoutStyles.checkoutStyles?.accentColor}
        mode={checkoutStyles.mode}
        placeholder="#EF4511"
        required
        inputValue={checkoutStyles.updatedCheckoutStyles?.accentColor}
        useColorInput
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              accentColor: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Text color"
        value={checkoutStyles.checkoutStyles?.textColor}
        mode={checkoutStyles.mode}
        placeholder="#151F26"
        required
        inputValue={checkoutStyles.updatedCheckoutStyles?.textColor}
        useColorInput
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              textColor: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Checkout background color"
        value={checkoutStyles.checkoutStyles?.checkoutBackgroundColor}
        mode={checkoutStyles.mode}
        placeholder="#FFFFFF"
        required
        inputValue={checkoutStyles.updatedCheckoutStyles?.checkoutBackgroundColor}
        useColorInput
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              checkoutBackgroundColor: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Summary background color"
        value={checkoutStyles.checkoutStyles?.summaryBackgroundColor}
        mode={checkoutStyles.mode}
        placeholder="#151F26"
        required
        inputValue={checkoutStyles.updatedCheckoutStyles?.summaryBackgroundColor}
        useColorInput
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              summaryBackgroundColor: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Summary text color"
        value={checkoutStyles.checkoutStyles?.summaryTextColor}
        mode={checkoutStyles.mode}
        placeholder="#FFFFFF"
        required
        inputValue={checkoutStyles.updatedCheckoutStyles?.summaryTextColor}
        useColorInput
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              summaryTextColor: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Summary button background color"
        value={checkoutStyles.checkoutStyles?.summaryButtonBackgroundColor}
        mode={checkoutStyles.mode}
        placeholder="#EFF1F5"
        required
        inputValue={checkoutStyles.updatedCheckoutStyles?.summaryButtonBackgroundColor}
        useColorInput
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              summaryButtonBackgroundColor: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Summary button text color"
        value={checkoutStyles.checkoutStyles?.summaryButtonTextColor}
        mode={checkoutStyles.mode}
        placeholder="#151F26"
        required
        inputValue={checkoutStyles.updatedCheckoutStyles?.summaryButtonTextColor}
        useColorInput
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              summaryButtonTextColor: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Buttons color"
        value={checkoutStyles.checkoutStyles?.buttonsColor}
        mode={checkoutStyles.mode}
        placeholder="#151F26"
        required
        inputValue={checkoutStyles.updatedCheckoutStyles?.buttonsColor}
        useColorInput
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              buttonsColor: e.target.value,
            }),
          )
        }
      />
      <TextField
        title="Logo URL"
        value={checkoutStyles.checkoutStyles?.logoUrl}
        mode={checkoutStyles.mode}
        placeholder="https://example.com/image.png"
        inputValue={checkoutStyles.updatedCheckoutStyles?.logoUrl}
        onChange={(e) =>
          dispatch(
            storeSlice.actions.setUpdatedCheckoutStyles({
              logoUrl: e.target.value,
            }),
          )
        }
      />
    </Card>
  )
}
