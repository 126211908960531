import { FC, Fragment } from 'react'
import { Merchant } from '@softcery/qc-apiclient'
import { useGate, useStore } from 'effector-react'
// TODO: replace with shared/textarea
import { Textarea } from '~/components'
import { Error, H2 } from '~/shared/ui/atoms'
import {
  ButtonWithLoader,
  InputWithLabel,
  Popup as BasePopup,
} from '~/shared/ui/molecules'
import { createFx, $fields, $createStatus, Gate, setFields } from '../../../model'
import { styles } from './styles'

interface Props {
  onClose: () => void
  onCreateComplete: (merchantId: string) => void
}

export const Popup: FC<Props> = ({ onClose, onCreateComplete }) => {
  const { error, loading } = useStore($createStatus)
  const fields = useStore($fields)

  const handleChange =
    (key: keyof Merchant) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setFields({ [key]: event.target.value })

  const onCreate = () =>
    createFx({
      fields: {
        merchant: { ...fields },
      },
    }).then((action) => {
      onCreateComplete(action.merchantId || '')
    })

  useGate(Gate)

  return (
    <BasePopup
      title={<H2>New Merchant</H2>}
      content={
        <Fragment>
          <InputWithLabel
            label="Name"
            type="text"
            placeholder="John Doe"
            required
            value={fields.name}
            onChange={handleChange('name')}
            extendStyles={{ inputContainer: styles.inputContainer, label: styles.label }}
          />
          <InputWithLabel
            label="Email"
            type="text"
            placeholder="john.doe@gmail.cpm"
            onChange={handleChange('email')}
            value={fields.email}
            extendStyles={{ inputContainer: styles.inputContainer, label: styles.label }}
          />
          <InputWithLabel
            label="Website"
            type="text"
            placeholder="https://example.com"
            value={fields.website}
            onChange={handleChange('website')}
            extendStyles={{ inputContainer: styles.inputContainer, label: styles.label }}
          />
          <Textarea
            label="Note"
            placeholder="Any additional info"
            value={fields.memoText || ''}
            onChange={handleChange('memoText')}
          />

          {error && <Error extendStyle={styles.error}>{error.message}</Error>}
        </Fragment>
      }
      footer={
        <ButtonWithLoader disabled={!fields.name} onClick={onCreate}>
          CREATE MERCHANT
        </ButtonWithLoader>
      }
      extendStyle={{ content: styles.content }}
      loading={loading}
      onClose={onClose}
    />
  )
}
