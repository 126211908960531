import { Store, DeleteStoreRequest } from '@softcery/qc-apiclient'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { apiService, getErrorMessage } from '~/shared/api'

export const deleteStore = createAsyncThunk(
  '/deleteStore',
  async (requestOptions: DeleteStoreRequest, { rejectWithValue }) => {
    try {
      return await apiService().deleteStore({
        storeId: requestOptions.storeId,
        ownerMerchantId: requestOptions.ownerMerchantId,
      })
    } catch (res: any) {
      const errorMessage = await getErrorMessage(res)
      console.error(`failed to delete store`, errorMessage)
      return rejectWithValue(errorMessage)
    }
  },
)

const initialState = {
  fields: null as Store | null,
  loading: false,
  error: '',
}

export const deleteStoreSlice = createSlice({
  name: 'deleteStore',
  initialState,
  reducers: {
    setFields: (state, action: PayloadAction<Store | null>) => {
      state.fields = action.payload
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteStore.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    // dispatch(push(`/merchant/${store.ownerMerchantId}`))
    builder.addCase(deleteStore.fulfilled, (state) => {
      state.loading = false
      state.error = ''
      state.fields = null
    })
    builder.addCase(deleteStore.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as string
    })
  },
})
