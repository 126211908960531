import { FC, Fragment } from 'react'
import { SerializedStyles } from '@emotion/react'
import { Modes, CardHeaderTypes } from '~/types'
import { styles } from './styles'
import { SecondaryButton } from '..'

interface CardHeaderProps {
  mode?: Modes
  type?: CardHeaderTypes
  title: any

  extendStyle?: SerializedStyles

  disableEditButton?: boolean
  onEdit?: () => void

  disableSaveButton?: boolean
  onSave?: () => void

  showResetButton?: boolean
  onReset?: () => void

  onDiscard?: () => void
  onCreate?: () => void
}

export const CardHeader: FC<CardHeaderProps> = ({
  mode,
  type,
  title,

  extendStyle,

  disableEditButton,
  onEdit,

  disableSaveButton,
  onSave,

  showResetButton,
  onReset,

  onDiscard,
  onCreate,
}) => {
  return (
    <div css={[styles.container, extendStyle]}>
      {title}
      <div css={styles.buttons}>
        {mode === Modes.Displaying && (
          <SecondaryButton
            disabled={disableEditButton}
            onClick={() => (type === CardHeaderTypes.Edit ? onEdit?.() : onCreate?.())}
          >
            {type === CardHeaderTypes.Edit ? 'EDIT' : 'CREATE'}{' '}
            <img
              src={
                type === CardHeaderTypes.Edit
                  ? require('./assets/edit.svg')
                  : require('./assets/plus-square.svg')
              }
              alt="edit icon"
            />
          </SecondaryButton>
        )}

        {mode === Modes.Editing && (
          <Fragment>
            {showResetButton && (
              <SecondaryButton onClick={() => onReset?.()}>
                RESET
                <img src={require('./assets/reset.svg')} alt="reset icon" />
              </SecondaryButton>
            )}
            <SecondaryButton onClick={onDiscard} extendStyle={styles.cancel}>
              DISCARD <img src={require('./assets/discard.svg')} alt="discard icon" />
            </SecondaryButton>

            <SecondaryButton
              disabled={disableSaveButton}
              onClick={onSave}
              extendStyle={styles.submit}
            >
              SAVE <img src={require('./assets/save.svg')} alt="save icon" />
            </SecondaryButton>
          </Fragment>
        )}
      </div>
    </div>
  )
}
