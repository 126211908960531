import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { viewerSlice } from '~/entities/viewer'
import { authSlice } from '~/features/auth'
import { deleteStoreSlice } from '~/features/store/delete-store'
import { merchantDashboardSlice } from '~/pages/merchant/dashboard/redux'
import { storeSlice } from '~/pages/merchant/store/redux'

// browser history
export const history = createBrowserHistory()

// store provides redux store for whole app
export const store = configureStore({
  reducer: {
    router: connectRouter(history),
    viewer: viewerSlice.reducer,
    auth: authSlice.reducer,
    merchantDashboard: merchantDashboardSlice.reducer,
    deleteStore: deleteStoreSlice.reducer,
    store: storeSlice.reducer,
  },

  middleware: [...getDefaultMiddleware().concat(routerMiddleware(history))],
})

// Infer the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
