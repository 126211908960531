import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AUTH_EMAIL_KEY } from '~/shared/config/constants'
import { styles } from './styles'

// Sidebar provides sidebar component with navigation to other screens.
// Used on Merchants, Create merchant screen.
export const Sidebar: FC = () => {
  const { pathname } = useLocation()

  return (
    <div css={[styles.container]}>
      <div css={styles.item}>
        <Link to="/merchants">
          <img src={require('./assets/logo.svg')} alt="logo" />
        </Link>

        <div css={styles.separator} />

        <Link
          to="/merchants"
          css={[
            styles.menuItem.base,
            pathname.includes('merchants') && styles.menuItem.active,
          ]}
        >
          <img src={require('./assets/merchants.svg')} alt="merchants" />
        </Link>

        <Link
          to="/stores"
          css={[styles.menuItem.base, pathname === '/stores' && styles.menuItem.active]}
        >
          <img src={require('./assets/stores.svg')} alt="stores" />
        </Link>
      </div>

      <div css={styles.item}>
        <Link
          to="/settings"
          css={[styles.menuItem.base, pathname === '/settings' && styles.menuItem.active]}
        >
          <img src={require('./assets/settings.svg')} alt="settings" />
        </Link>

        <div css={styles.avatar}>
          {localStorage.getItem(AUTH_EMAIL_KEY)?.charAt(0).toUpperCase()}
        </div>
      </div>
    </div>
  )
}
