import { useState, FC, InputHTMLAttributes } from 'react'
import { SerializedStyles } from '@emotion/react'
import { styles } from './styles'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: string
  dataTestId?: string

  extendStyle?: {
    container?: SerializedStyles
    input?: SerializedStyles
  }
}

export const Input: FC<Props> = ({
  onChange,
  type,
  dataTestId,

  extendStyle,
  ...props
}) => {
  const [inputType, setInputType] = useState<string>(type || 'text')

  return (
    <div css={[styles.inputContainer, extendStyle?.container]}>
      <input
        {...props}
        css={[
          styles.input,
          inputType === 'password' && styles.password,
          extendStyle?.input,
        ]}
        onChange={onChange}
        type={inputType}
        data-testid={dataTestId}
      />

      {type === 'password' && (
        <img
          src={
            inputType === 'password'
              ? require('./assets/eye-closed.svg')
              : require('./assets/eye-open.svg')
          }
          alt={inputType === 'password' ? 'invisible' : 'visible'}
          onClick={() =>
            inputType === 'password' ? setInputType('text') : setInputType('password')
          }
          css={styles.icon}
        />
      )}
    </div>
  )
}
