import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface Props {
  text: string
  extendStyle?: SerializedStyles
}

// NoItems provides a text that displays "not found message".
export const NoItems: FC<Props> = ({ text, extendStyle }) => {
  return (
    <div css={[styles.container, extendStyle]}>
      <img src={require('./assets/info.svg')} alt="info" />
      <span>{text}</span>
    </div>
  )
}
