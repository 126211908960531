import { css } from '@emotion/react'
import { breakpoints, Colors } from '~/shared/styles'

export const styles = {
  container: css`
    width: 100%;
    height: 100vh;

    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: ${Colors.DARK_2};

    ${breakpoints.mobile} {
      padding: 0 10px;
    }
  `,
}
