import { BigcommerceConfiguration } from '@softcery/qc-apiclient'
import { useStore } from 'effector-react'
import { useState } from 'react'

import { Card as BaseCard, CardHeader, TextField } from '~/components'
import {
  $bigcommerceConfiguration,
  $getBigcommerceConfigurationStatus,
} from '~/entities/bigcommerce-configuration'
import {
  $updateBigcommerceConfigurationStatus,
  $updateBigcommerceConfigurationFields,
  setUpdateBigcommerceConfigurationFields,
  updateBigcommerceConfiguration,
} from '~/features/update-bigcommerce-configuration'
import { CardHeaderTypes, Modes } from '~/types'

import { styles } from './styles'

export const Card = () => {
  const [displayingMode, setDisplayingMode] = useState<Modes>(Modes.Displaying)

  const { loading: getLoading } = useStore($getBigcommerceConfigurationStatus)
  const bigcommerceConfiguration = useStore($bigcommerceConfiguration)

  const { error: updateError } = useStore($updateBigcommerceConfigurationStatus)
  const updateBigcommerceConfigurationFields = useStore(
    $updateBigcommerceConfigurationFields,
  )

  const handleChange =
    (key: keyof BigcommerceConfiguration) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setUpdateBigcommerceConfigurationFields({ [key]: event.target.value })

  const onEdit = (): void => {
    // set updated integration
    setUpdateBigcommerceConfigurationFields(bigcommerceConfiguration)

    setDisplayingMode(Modes.Editing)
  }

  const onCancelEditing = (): void => {
    // set updated integration to integration from api
    setUpdateBigcommerceConfigurationFields(bigcommerceConfiguration)

    setDisplayingMode(Modes.Displaying)
  }

  const onSave = () => updateBigcommerceConfiguration()

  const disableSaveConfiguration: boolean = getLoading || Boolean(updateError?.message)

  return (
    <BaseCard
      loading={getLoading && displayingMode === Modes.Displaying}
      error={updateError?.message || ''}
      showContent={
        displayingMode === Modes.Editing ||
        (displayingMode === Modes.Displaying && !getLoading)
      }
      extendStyle={{ body: styles.cardBody }}
      header={
        <CardHeader
          mode={displayingMode}
          type={CardHeaderTypes.Edit}
          title="BC Public App"
          onEdit={onEdit}
          disableEditButton={Boolean(getLoading)}
          onSave={onSave}
          disableSaveButton={disableSaveConfiguration}
          onDiscard={onCancelEditing}
        />
      }
    >
      <TextField
        title="Client ID"
        value={bigcommerceConfiguration.clientId}
        mode={displayingMode}
        useTextarea
        placeholder="t5cnlawh994a1y9no44d9onf2bmnvf2"
        inputValue={updateBigcommerceConfigurationFields.clientId}
        onChange={handleChange('clientId')}
      />
      <TextField
        title="Client Secret"
        value={bigcommerceConfiguration.clientSecret}
        mode={displayingMode}
        useTextarea
        placeholder="aaab2725a406508bda97d7cd2e7dc3ba1"
        inputValue={updateBigcommerceConfigurationFields.clientSecret}
        onChange={handleChange('clientSecret')}
      />
    </BaseCard>
  )
}
