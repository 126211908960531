import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    background: none;
  `,

  popup: css`
    border-top: 4px solid ${Colors.ACCENT_1};
    box-shadow: 0px 20px 30px ${Colors.LIGHT_13};
    max-width: 450px;
  `,

  title: {
    container: css`
      display: flex;
    `,

    regular: css`
      font-weight: 300;
      margin-left: 5px;
    `,
  },

  inputContainer: css`
    margin-bottom: 16px;
  `,

  label: css`
    margin-bottom: 8px;
  `,

  error: css`
    margin-top: 18px;
  `,

  button: css`
    margin-top: 18px;
  `,
}
