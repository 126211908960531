import { css } from '@emotion/react'
import { Colors, breakpoints } from '~/shared/styles'

export const styles = {
  container: css`
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${Colors.DARK_5};
    backdrop-filter: blur(6px);
  `,

  popup: css`
    width: 100%;
    max-width: 440px;

    box-sizing: border-box;
    padding: 24px;

    font-family: Montserrat, sans-serif;
    font-style: normal;

    background: ${Colors.LIGHT_1};
    box-shadow: 0px 15px 25px ${Colors.LIGHT_13};
    border-radius: 5px;

    ${breakpoints.mobile} {
      margin: 0 10px;
    }
  `,

  header: {
    container: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    title: css`
      margin: 0;

      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      color: ${Colors.DARK_2};
    `,

    closeIcon: {
      base: css`
        cursor: pointer;
      `,

      disable: css`
        pointer-events: none;
        opacity: 0.6;
      `,
    },
  },

  content: css`
    margin: 25px 0;
  `,
}
