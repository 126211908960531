import { FC, HTMLAttributes } from 'react'
import { styles } from './styles'

interface CheckboxProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  checked: boolean
  dataTestId: string

  setChecked: () => any
}

export const CheckboxWithLabel: FC<CheckboxProps> = ({
  checked,
  setChecked,
  label,
  dataTestId,
  ...props
}) => {
  return (
    <div css={styles.container} onClick={setChecked} {...props} data-testid={dataTestId}>
      <div css={styles.checkbox}>
        {checked && (
          <img src={require('./assets/checked.svg')} alt="checked" css={styles.icon} />
        )}
      </div>
      <label css={[styles.label]}>{label}</label>
    </div>
  )
}
