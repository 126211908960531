import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 8px 8px 16px;

    font-weight: 600;
    font-size: 12px;
    line-height: 11px;
    color: ${Colors.DARK_1};

    background: ${Colors.LIGHT_10};
    border-radius: 7px 7px 0 0;
  `,

  buttons: css`
    display: flex; ;
  `,

  cancel: css`
    margin-right: 8px;

    font-weight: 600;
  `,

  submit: css`
    font-weight: 600;
    color: ${Colors.SUCCESS_1};
  `,
}
