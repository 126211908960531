import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  inputContainer: css`
    margin-bottom: 16px;
  `,

  textSelect: {
    select: {
      value: {
        base: css`
          box-sizing: border-box;
          height: 45px;
          padding: 14.5px 16px;

          font-weight: 500;
          font-size: 15px;
          line-height: 15px;
          color: ${Colors.DARK_1};

          background: ${Colors.LIGHT_2};
          border: 1px solid ${Colors.DARK_3};
          border-radius: 8px;
        `,

        placeholder: css`
          font-weight: 500;
          font-size: 15px;
          line-height: 15px;
          color: ${Colors.DARK_3};
        `,
      },
    },

    title: {
      base: css`
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: ${Colors.DARK_1};
      `,
    },
  },

  label: css`
    margin-bottom: 8px;
  `,

  error: css`
    margin-top: 18px;
  `,
}
