import { FC, Fragment } from 'react'
import { useGate, useStore } from 'effector-react'
import { Button, Error, H2 } from '~/shared/ui/atoms'
import { ButtonWithLoader, Popup as BasePopup } from '~/shared/ui/molecules'
import {
  deleteMerchantFx,
  DeleteMerchantGate,
  $deleteMerchantFields,
  $deleteMerchantStatus,
} from '~/features/merchant/delete-merchant'
import { styles } from './styles'

interface Props {
  onClose: () => void
}

export const Popup: FC<Props> = ({ onClose }) => {
  const { loading, error } = useStore($deleteMerchantStatus)
  const fields = useStore($deleteMerchantFields)

  const onDelete = () => deleteMerchantFx({ id: fields!.id! }).then(() => onClose())

  useGate(DeleteMerchantGate)

  return (
    <BasePopup
      title={<H2>Delete “{fields?.name}”</H2>}
      content={
        <Fragment>
          <span
            css={styles.text}
          >{`Are you sure you want to delete “${fields?.name}”? All data of this merchant will be deleted, including stores and all their associated data.`}</span>

          {error && <Error extendStyle={styles.error}>{error}</Error>}
        </Fragment>
      }
      footer={
        <div css={styles.buttons}>
          <ButtonWithLoader loading={loading} disabled={loading} onClick={onDelete}>
            DELETE MERCHANT
          </ButtonWithLoader>
          <Button disabled={loading} extendStyle={styles.cancel} onClick={onClose}>
            CANCEL
          </Button>
        </div>
      }
      onClose={onClose}
    />
  )
}
