import { FC, Fragment, ReactNode } from 'react'
import { styles } from './styles'

interface WithSidebarProps {
  sidebar: ReactNode
  content: ReactNode
}

export const WithSidebar: FC<WithSidebarProps> = ({ sidebar, content }) => {
  return (
    <Fragment>
      {sidebar}
      <div css={styles.content}>{content}</div>
    </Fragment>
  )
}
