import { PlatformOption } from './features/integration/create-integration/types'
// options for platform select
export const PLATFORM_OPTIONS: PlatformOption[] = [
  { title: 'Shopify', value: 'shopify' },
  { title: 'Bigcommerce', value: 'bigcommerce' },
]

// icon and text for integration and gateway statuses
export const INTEGRATION_AND_GATEWAY_STATUSES = {
  active: {
    icon: require('./shared/assets/check.svg'),
    text: 'Successful installation',
  },
  pending: {
    icon: require('./shared/assets/tool.svg'),
    text: 'Awaiting application installation',
  },
  failed: {
    icon: require('./shared/assets/alert.svg'),
    text: '',
  },
}
