import { Store } from '@softcery/qc-apiclient'
import { createGate } from 'effector-react'
import { combine, createEffect, createEvent, createStore, restore } from 'effector'
import { apiService, getErrorMessage } from '~/shared/api'

export const createFx = createEffect(async (store: Store) => {
  try {
    return await apiService().createStore({ fields: { store } })
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to create store`, errorMessage)
    return Promise.reject(errorMessage)
  }
})

export const Gate = createGate()

export const setFields = createEvent<Partial<Store>>()

export const $fields = createStore<Store>({ name: '', ownerMerchantId: '' })
  .on(setFields, (state, payload) => ({ ...state, ...payload }))
  .reset([createFx.done, Gate.close])

export const $createStatus = combine({
  loading: createFx.pending,
  error: restore(createFx.failData, null),
})
