import {
  Store,
  GetOrdersRequest,
  GetOrdersResponseBody,
  Eye4fraudIntegration,
  CheckoutStyles,
  CheckoutSettings,
  PaymentGateway,
} from '@softcery/qc-apiclient'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { apiService } from '~/shared/api'
import { Modes } from '~/types'

export const getOrders = createAsyncThunk(
  '/orders',
  async (requestOptions: GetOrdersRequest) => {
    const response = await apiService().getOrders(requestOptions)

    return response
  },
)

// initial state of storeSlice
const initialState = {
  store: {
    store: null as null | Store,
    updatedStore: null as null | Store,
    loading: true,
    error: '',
    mode: Modes.Displaying as Modes,
  },

  gateway: {
    gateway: null as null | PaymentGateway,
    updatedGateway: null as null | PaymentGateway,
    loading: true,
    error: '',
    mode: Modes.Displaying as Modes,
  },

  checkoutStyles: {
    checkoutStyles: null as null | CheckoutStyles,
    updatedCheckoutStyles: null as null | CheckoutStyles,
    loading: true,
    error: '',
    mode: Modes.Displaying as Modes,
  },

  eye4fraudIntegration: {
    integration: null as null | Eye4fraudIntegration,
    updatedIntegration: null as null | Eye4fraudIntegration,
    loading: true,
    error: '',
    mode: Modes.Displaying as Modes,
  },

  checkoutSettings: {
    checkoutSettings: null as null | CheckoutSettings,
    updatedCheckoutSettings: null as null | CheckoutSettings,
    loading: true,
    error: '',
    mode: Modes.Displaying as Modes,
  },

  orders: {
    loading: true,
    error: '',
    data: {} as GetOrdersResponseBody,
    requestOptions: {
      offset: 0,
      limit: 20,
    },
  },
}

// Store screen slice
export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    // start getting or updating store
    getOrUpdateOrDeleteStore: (state) => {
      state.store.error = ''
      state.store.loading = true
    },

    // getting or updating store succeeded
    getOrUpdateStoreSuccess: (state, action: PayloadAction<Store | null>) => {
      state.store.loading = false
      state.store.error = ''
      state.store.store = action.payload
    },

    // getting or updating or deleting store failed
    getOrUpdateOrDeleteStoreFailure: (state, action: PayloadAction<string>) => {
      state.store.loading = false
      state.store.error = action.payload
    },

    // deleting store succeeded
    deleteStoreSuccess: (state) => {
      state.store.error = ''
      state.store.loading = false
    },

    // set updated store
    setUpdatedStore: (state, action: PayloadAction<Partial<Store | null>>) => {
      state.store.updatedStore = {
        ...state.store.updatedStore,
        ...action.payload,
      } as Store

      // clear error
      state.store.error = ''
    },

    // set store mode
    setStoreMode: (state, action: PayloadAction<Modes>) => {
      state.store.mode = action.payload

      // clear error
      state.store.error = ''
    },

    // start getting or updating gateway
    getOrUpdateGateway: (state) => {
      state.gateway.error = ''
      state.gateway.loading = true
    },

    // getting or updating gateway succeeded
    getOrUpdateGatewaySuccess: (state, action: PayloadAction<PaymentGateway | null>) => {
      state.gateway.loading = false
      state.gateway.error = ''
      state.gateway.gateway = action.payload
    },

    // getting or updating gateway failed
    getOrUpdateGatewayFailure: (state, action: PayloadAction<string>) => {
      state.gateway.loading = false
      state.gateway.error = action.payload
    },

    // set updated gateway
    setUpdatedGateway: (state, action: PayloadAction<Partial<PaymentGateway | null>>) => {
      state.gateway.updatedGateway = {
        ...state.gateway.updatedGateway,
        ...action.payload,
      }

      // clear error
      state.gateway.error = ''
    },

    // set gateway mode
    setGatewayMode: (state, action: PayloadAction<Modes>) => {
      state.gateway.mode = action.payload

      // clear error
      state.gateway.error = ''
    },

    // start getting or updating checkout styles
    getOrUpdateCheckoutStyles: (state) => {
      state.checkoutStyles.error = ''
      state.checkoutStyles.loading = true
    },

    // getting or updating checkout styles succeeded
    getOrUpdateCheckoutStylesSuccess: (
      state,
      action: PayloadAction<CheckoutStyles | null>,
    ) => {
      state.checkoutStyles.loading = false
      state.checkoutStyles.error = ''
      state.checkoutStyles.checkoutStyles = action.payload
    },

    // getting or updating checkout styles failed
    getOrUpdateCheckoutStylesFailure: (state, action: PayloadAction<string>) => {
      state.checkoutStyles.loading = false
      state.checkoutStyles.error = action.payload
    },

    // set updated checkout styles
    setUpdatedCheckoutStyles: (
      state,
      action: PayloadAction<Partial<CheckoutStyles | null>>,
    ) => {
      state.checkoutStyles.updatedCheckoutStyles = {
        ...state.checkoutStyles.updatedCheckoutStyles,
        ...action.payload,
      } as CheckoutStyles

      // clear error
      state.checkoutStyles.error = ''
    },

    // set checkout styles mode
    setCheckoutStylesMode: (state, action: PayloadAction<Modes>) => {
      state.checkoutStyles.mode = action.payload

      // clear error
      state.checkoutStyles.error = ''
    },

    // start getting or updating eye4fraud integration
    getOrUpdateEye4fraudIntegration: (state) => {
      state.eye4fraudIntegration.error = ''
      state.eye4fraudIntegration.loading = true
    },

    // getting or updating eye4fraud integration succeeded
    getOrUpdateEye4fraudIntegrationSuccess: (
      state,
      action: PayloadAction<Eye4fraudIntegration | null>,
    ) => {
      state.eye4fraudIntegration.loading = false
      state.eye4fraudIntegration.error = ''
      state.eye4fraudIntegration.integration = action.payload
    },

    // getting or updating eye4fraud integration failed
    getOrUpdateEye4fraudIntegrationFailure: (state, action: PayloadAction<string>) => {
      state.eye4fraudIntegration.loading = false
      state.eye4fraudIntegration.error = action.payload
    },

    // set updated eye4fraud integration
    setUpdatedEye4fraudIntegration: (
      state,
      action: PayloadAction<Partial<Eye4fraudIntegration | null>>,
    ) => {
      state.eye4fraudIntegration.updatedIntegration = {
        ...state.eye4fraudIntegration.updatedIntegration,
        ...action.payload,
      } as Eye4fraudIntegration

      // clear error
      state.eye4fraudIntegration.error = ''
    },

    // set eye4fraud integration mode
    setEye4fraudIntegrationMode: (state, action: PayloadAction<Modes>) => {
      state.eye4fraudIntegration.mode = action.payload

      // clear error
      state.eye4fraudIntegration.error = ''
    },

    // start getting or updating checkout settings
    getOrUpdateCheckoutSettings: (state) => {
      state.checkoutSettings.error = ''
      state.checkoutSettings.loading = true
    },

    // getting or updating checkout settings succeeded
    getOrUpdateCheckoutSettingsSuccess: (
      state,
      action: PayloadAction<CheckoutSettings | null>,
    ) => {
      state.checkoutSettings.loading = false
      state.checkoutSettings.error = ''
      state.checkoutSettings.checkoutSettings = action.payload
    },

    // getting or updating checkout settings failed
    getOrUpdateCheckoutSettingsFailure: (state, action: PayloadAction<string>) => {
      state.checkoutSettings.loading = false
      state.checkoutSettings.error = action.payload
    },

    // set updated checkout settings
    setUpdatedCheckoutSettings: (
      state,
      action: PayloadAction<Partial<CheckoutSettings | null>>,
    ) => {
      state.checkoutSettings.updatedCheckoutSettings = {
        ...state.checkoutSettings.updatedCheckoutSettings,
        ...action.payload,
      } as CheckoutSettings

      // clear error
      state.checkoutSettings.error = ''
    },

    // set checkout settings mode
    setCheckoutSettingsMode: (state, action: PayloadAction<Modes>) => {
      state.checkoutSettings.mode = action.payload

      // clear error
      state.checkoutSettings.error = ''
    },

    // set request options for orders
    setRequestOptions: (state, action: PayloadAction<Partial<GetOrdersRequest>>) => {
      state.orders.requestOptions = { ...state.orders.requestOptions, ...action.payload }
    },

    // clear redux state
    clearState: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.orders.loading = true
    })
    builder.addCase(
      getOrders.fulfilled,
      (state, action: PayloadAction<GetOrdersResponseBody>) => {
        state.orders.data = action.payload
        state.orders.loading = false
      },
    )
    builder.addCase(getOrders.rejected, (state, action) => {
      state.orders.error = action.error.message || 'Failed to fetch orders'
      state.orders.loading = false
    })
  },
})
