import { GetIntegrationResponse, Integration } from '@softcery/qc-apiclient'
import { combine, createEffect, createEvent, createStore, restore } from 'effector'
import { createGate } from 'effector-react'
import { apiService, getErrorMessage } from '~/shared/api'

export const deleteFx = createEffect(async (storeId: string) => {
  try {
    return await apiService().deleteIntegration({ storeId })
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to delete integration`, errorMessage)
    return Promise.reject(errorMessage)
  }
})

export const getFx = createEffect<
  { storeId: string; intervalFetch?: boolean },
  GetIntegrationResponse,
  { message: string; status: number }
>(async ({ storeId }) => {
  try {
    return await apiService().getIntegration({ storeId })
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to get integration`, errorMessage)
    const errorObject = { message: errorMessage, status: res.status }
    throw errorObject
  }
})

export const Gate = createGate()

export const set = createEvent<Integration>()
export const $fields = createStore<Integration>({})
  .on(getFx.doneData, (_, payload) => {
    if (
      payload.integration?.status !== 'active' &&
      !payload.integration?.isUsedPublicApp
    ) {
      return {}
    }
    return payload.integration
  })
  .on(set, (_, payload) => payload)
  .reset([deleteFx.done, Gate.close])

export const $isActive = createStore<boolean>(false)
  .on(getFx.doneData, (_, payload) => payload.integration?.status === 'active')
  .reset(Gate.close)

const setIntervalFetch = createEvent<boolean>()
const $intervalFetch = createStore<boolean>(false).on(
  setIntervalFetch,
  (_, payload) => payload,
)

getFx.watch((payload) => {
  if (payload.intervalFetch) {
    setIntervalFetch(true)
  }
})

export const $getStatus = combine({
  loading: getFx.pending,
  error: restore(getFx.failData, null),
  intervalFetch: restore($intervalFetch),
})
