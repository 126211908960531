// options for order mode select
export const ORDER_MODE_OPTIONS = [
  { title: 'Test', value: 'test' },
  { title: 'Cash', value: 'cash' },
]

export const TRANSACTION_MODE_OPTIONS = [
  { title: 'Authorize only', value: 'enabled' },
  { title: 'Authorize and Capture', value: 'disabled' },
]
