import { combine, createEffect, createEvent, createStore, restore } from 'effector'
import { createGate } from 'effector-react'
import { Merchant, DeleteMerchantRequest } from '@softcery/qc-apiclient'
import { apiService, getErrorMessage } from '~/shared/api'

export const deleteFx = createEffect(async (requestOptions: DeleteMerchantRequest) => {
  try {
    return await apiService().deleteMerchant(requestOptions)
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to delete merchant`, errorMessage)
    return Promise.reject(errorMessage)
  }
})

export const Gate = createGate()

export const setFields = createEvent<Merchant>()

export const $fields = createStore<Merchant | null>(null)
  .on(setFields, (_, payload) => payload)
  .reset(Gate.close)

export const $deleteStatus = combine({
  loading: deleteFx.pending,
  error: restore(deleteFx.failData, null),
})
