import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { styles } from './styles'

interface Props {
  extendStyle?: SerializedStyles
}

// TODO: rename it
export const H2: FC<Props> = ({ extendStyle, children }) => {
  return <h2 css={[styles.base, extendStyle]}>{children}</h2>
}
