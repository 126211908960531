import { useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import { useAppSelector, useAppDispatch } from '~/shared/model'
import { AUTH_EMAIL_KEY, AUTH_TOKEN_KEY } from '~/shared/config/constants'
import { viewerSlice } from '~/entities/viewer'

import { Login } from './login'
import { Merchants } from './merchants'
import { Merchant } from './merchant'
import { StoresScreen } from './stores'
import { Settings } from './settings'

export const Routing = () => {
  const dispatch = useAppDispatch()
  const { token } = useAppSelector((s) => s.viewer)
  // check if token is expired
  useMemo(() => {
    if (token) {
      const decodedToken = jwtDecode<JwtPayload>(token)

      // logout if expired token
      if (Date.now() >= decodedToken!.exp! * 1000) {
        localStorage.removeItem(AUTH_TOKEN_KEY)
        localStorage.removeItem(AUTH_EMAIL_KEY)
        sessionStorage.removeItem(AUTH_TOKEN_KEY)
        sessionStorage.removeItem(AUTH_EMAIL_KEY)
        dispatch(viewerSlice.actions.setToken(''))
      }
    }
  }, [token])

  if (!token) {
    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Redirect from="*" to="/login" />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/merchant" component={Merchant} />

      <Route path="/merchants" exact component={Merchants} />
      <Route path="/settings" component={Settings} />
      <Redirect from="*" to="/merchants" />
      <Route path="/stores" component={StoresScreen} />
    </Switch>
  )
}
