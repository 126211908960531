import { FC } from 'react'
import { styles } from './styles'

export const StoresScreen: FC = () => {
  return (
    <div css={styles.container}>
      <p>Stores screen not implemented yet...</p>
    </div>
  )
}
