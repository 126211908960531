import { Integration } from '@softcery/qc-apiclient'

import { IntegrationStatuses, Status } from '~/types'
import { INTEGRATION_AND_GATEWAY_STATUSES } from '~/constants'

// TODO: move it into entities/integration/lib
// get store integration status icon and text
export const getIntegrationStatus = (integration: Integration): Status => {
  // integration status
  const { status } = integration

  // if failed status, text - from api
  if (status === IntegrationStatuses.Failed) {
    return {
      icon: INTEGRATION_AND_GATEWAY_STATUSES.failed.icon,
      text: integration?.message || '',
    }
  }

  // pending, success statuses, no status
  return status
    ? INTEGRATION_AND_GATEWAY_STATUSES[integration?.status as IntegrationStatuses]
    : INTEGRATION_AND_GATEWAY_STATUSES.pending
}
