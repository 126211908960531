import { ChangeEvent, FC } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { styles } from './styles'

interface TextareaProps {
  label: string
  placeholder: string
  value: string

  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => any
}

export const Textarea: FC<TextareaProps> = ({ label, placeholder, value, onChange }) => {
  return (
    <div css={styles.container}>
      <label css={styles.label}>{label}</label>

      <TextareaAutosize
        css={styles.textarea}
        maxRows={5}
        maxLength={1000}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
