import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    box-sizing: border-box;
    padding: 20px 16px;

    display: grid;
    grid-gap: 10px;
    align-content: center;

    white-space: pre-wrap;
    word-break: break-word;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${Colors.DARK_1};

    background: ${Colors.LIGHT_8};
    border-bottom: 1px solid ${Colors.LIGHT_11};
  `,

  restProducts: css`
    color: ${Colors.DARK_3};
  `,
}
