import {
  DefaultApi,
  Store,
  Eye4fraudIntegration,
  CheckoutStyles,
  CheckoutSettings,
  PaymentGateway,
} from '@softcery/qc-apiclient'
import { push } from 'connected-react-router'

import { AppDispatch } from '~/app/redux'
import { getErrorMessage } from '~/shared/api/getErrorMessage'
import { Modes } from '~/types'

import { storeSlice } from './slice'

// UpdateStoreOptions is used to parameterize updateStore.
interface UpdateStoreOptions {
  storeId: string
  store: Store
}

// UpdateGatewayOptions is used to parameterize updateGateway.
interface UpdateGatewayOptions {
  storeId: string
  gateway: PaymentGateway
}

// UpdateCheckoutStylesOptions is used to parameterize updateCheckoutStyles.
interface UpdateCheckoutStylesOptions {
  storeId: string
  checkoutStyles: CheckoutStyles
}

// UpdateEye4fraudIntegrationOptions is used to parameterize updateEye4fraudIntegration.
interface UpdateEye4fraudIntegrationOptions {
  storeId: string
  eye4fraudIntegration: Eye4fraudIntegration
}

// UpdateCheckoutSettingsOptions is used to parameterize updateCheckoutSettings.
interface UpdateCheckoutSettingsOptions {
  storeId: string
  checkoutSettings: CheckoutSettings
}

export class StoreActions {
  constructor(private api: DefaultApi) {}

  // getStore used for getting store info
  public getStore =
    (storeId: string): any =>
    async (dispatch: AppDispatch) => {
      // start getting store
      dispatch(storeSlice.actions.getOrUpdateOrDeleteStore())

      try {
        // get store request
        const data = await this.api.getStores({ storeId })

        // get store succeeded
        dispatch(storeSlice.actions.getOrUpdateStoreSuccess(data.stores?.[0] || null))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // getting store failed
        console.error(`failed to get store`, errorMessage)
        dispatch(storeSlice.actions.getOrUpdateOrDeleteStoreFailure(String(errorMessage)))
      }
    }

  // updateStore used for store updating
  public updateStore =
    ({ storeId, store }: UpdateStoreOptions): any =>
    async (dispatch: AppDispatch) => {
      // start updating store
      dispatch(storeSlice.actions.getOrUpdateOrDeleteStore())

      try {
        // update store request
        await this.api.updateStore({
          id: storeId,
          fields: { store },
        })

        // update store succeeded
        dispatch(storeSlice.actions.getOrUpdateStoreSuccess(store))

        // set mode to displaying
        dispatch(storeSlice.actions.setStoreMode(Modes.Displaying))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // updating store failed
        console.error(`failed to update store`, errorMessage)
        dispatch(storeSlice.actions.getOrUpdateOrDeleteStoreFailure(String(errorMessage)))
      }
    }

  // deleteStore used for store deleting
  public deleteStore =
    (store: Store): any =>
    async (dispatch: AppDispatch) => {
      // start delete store
      dispatch(storeSlice.actions.getOrUpdateOrDeleteStore())

      try {
        // delete store request
        await this.api.deleteStore({ storeId: store.id! })

        // delete store succeeded
        dispatch(storeSlice.actions.deleteStoreSuccess())

        // navigate to merchant screen
        dispatch(push(`/merchant/${store.ownerMerchantId}`))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // deleting store failed
        console.error(`failed to delete store`, errorMessage)
        dispatch(storeSlice.actions.getOrUpdateOrDeleteStoreFailure(String(errorMessage)))
      }
    }

  // getGateway used for getting gateway
  public getGateway =
    (storeId: string): any =>
    async (dispatch: AppDispatch) => {
      // start getting gateway
      dispatch(storeSlice.actions.getOrUpdateGateway())

      try {
        // get gateway request
        const data = await this.api.getGateway({ storeId })

        // get gateway succeeded
        dispatch(storeSlice.actions.getOrUpdateGatewaySuccess(data.gateway || null))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // getting gateway failed
        console.error(`failed to get gateway`, errorMessage)
        dispatch(storeSlice.actions.getOrUpdateGatewayFailure(String(errorMessage)))
      }
    }

  // updateGateway used for gateway updating
  public updateGateway =
    ({ storeId, gateway }: UpdateGatewayOptions): any =>
    async (dispatch: AppDispatch) => {
      // start updating gateway
      dispatch(storeSlice.actions.getOrUpdateGateway())

      try {
        // update gateway request
        const data = await this.api.updateGateway({
          storeId,
          fields: {
            type: gateway.type!,
            usaepayApiKey: gateway.usaepayApiKey,
            usaepayApiPin: gateway.usaepayApiPin,
            authorizeNetLoginId: gateway.authorizeNetLoginId,
            authorizeNetTransactionKey: gateway.authorizeNetTransactionKey,
          },
        })

        // update gateway succeeded
        dispatch(storeSlice.actions.getOrUpdateGatewaySuccess(data.gateway || null))

        // set mode to displaying
        dispatch(storeSlice.actions.setGatewayMode(Modes.Displaying))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // updating gateway failed
        console.error(`failed to update gateway`, errorMessage)
        dispatch(storeSlice.actions.getOrUpdateGatewayFailure(String(errorMessage)))
      }
    }

  // getCheckoutStyles used for getting checkout styles
  public getCheckoutStyles =
    (storeId: string): any =>
    async (dispatch: AppDispatch) => {
      // start getting checkout styles
      dispatch(storeSlice.actions.getOrUpdateCheckoutStyles())

      try {
        // get checkout styles request
        const data = await this.api.getCheckoutStyles({ storeId })

        // get checkout styles succeeded
        dispatch(
          storeSlice.actions.getOrUpdateCheckoutStylesSuccess(
            data.checkoutStyles || null,
          ),
        )
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // getting checkout styles failed
        console.error(`failed to get checkout styles`, errorMessage)
        dispatch(
          storeSlice.actions.getOrUpdateCheckoutStylesFailure(String(errorMessage)),
        )
      }
    }

  // updateCheckoutStyles used for checkout styles updating
  public updateCheckoutStyles =
    ({ storeId, checkoutStyles }: UpdateCheckoutStylesOptions): any =>
    async (dispatch: AppDispatch) => {
      // start updating checkout styles
      dispatch(storeSlice.actions.getOrUpdateCheckoutStyles())

      try {
        // update checkout styles request
        const data = await this.api.updateCheckoutStyles({
          storeId,
          fields: {
            ...checkoutStyles,
          },
        })

        // update checkout styles succeeded
        dispatch(
          storeSlice.actions.getOrUpdateCheckoutStylesSuccess(
            data.checkoutStyles || null,
          ),
        )

        // set mode to displaying
        dispatch(storeSlice.actions.setCheckoutStylesMode(Modes.Displaying))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // updating checkout styles failed
        console.error(`failed to update checkout styles`, errorMessage)
        dispatch(
          storeSlice.actions.getOrUpdateCheckoutStylesFailure(String(errorMessage)),
        )
      }
    }

  // getEye4fraudIntegration used for getting eye4fraud integration
  public getEye4fraudIntegration =
    (storeId: string): any =>
    async (dispatch: AppDispatch) => {
      // start getting eye4fraud integration
      dispatch(storeSlice.actions.getOrUpdateEye4fraudIntegration())

      try {
        // get eye4fraud integration request
        const data = await this.api.getEye4fraudIntegration({ storeId })

        // get eye4fraud integration succeeded
        dispatch(
          storeSlice.actions.getOrUpdateEye4fraudIntegrationSuccess(
            data.eye4fraudIntegration || null,
          ),
        )
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // getting eye4fraud integration failed
        console.error(`failed to get eye4fraud integration`, errorMessage)
        dispatch(
          storeSlice.actions.getOrUpdateEye4fraudIntegrationFailure(String(errorMessage)),
        )
      }
    }

  // updateEye4fraudIntegration used for eye4fraud integration updating
  public updateEye4fraudIntegration =
    ({ storeId, eye4fraudIntegration }: UpdateEye4fraudIntegrationOptions): any =>
    async (dispatch: AppDispatch) => {
      // start updating eye4fraud integration
      dispatch(storeSlice.actions.getOrUpdateEye4fraudIntegration())

      try {
        // update eye4fraud integration request
        const data = await this.api.updateEye4fraudIntegration({
          storeId,
          fields: {
            apiLogin: eye4fraudIntegration.apiLogin!,
            apiKey: eye4fraudIntegration.apiKey!,
            enableStatus: eye4fraudIntegration.enableStatus!,
            automaticallyCancelFraudOrders:
              eye4fraudIntegration.automaticallyCancelFraudOrders!,
            automaticallyCaptureInsuredOrders:
              eye4fraudIntegration.automaticallyCaptureInsuredOrders!,
          },
        })

        // update eye4fraud integration succeeded
        dispatch(
          storeSlice.actions.getOrUpdateEye4fraudIntegrationSuccess(
            data.eye4fraudIntegration || null,
          ),
        )

        // set mode to displaying
        dispatch(storeSlice.actions.setEye4fraudIntegrationMode(Modes.Displaying))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // updating eye4fraud integration failed
        console.error(`failed to update eye4fraud integration`, errorMessage)
        dispatch(
          storeSlice.actions.getOrUpdateEye4fraudIntegrationFailure(String(errorMessage)),
        )
      }
    }

  // getCheckoutSettings used for getting checkout settings
  public getCheckoutSettings =
    (storeId: string): any =>
    async (dispatch: AppDispatch) => {
      // start getting checkout settings
      dispatch(storeSlice.actions.getOrUpdateCheckoutSettings())

      try {
        // get checkout settings request
        const data = await this.api.getCheckoutSettings({ storeId })

        // get checkout settings succeeded
        dispatch(
          storeSlice.actions.getOrUpdateCheckoutSettingsSuccess(
            data.checkoutSettings || null,
          ),
        )
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // getting checkout settings failed
        console.error(`failed to get checkout settings`, errorMessage)
        dispatch(
          storeSlice.actions.getOrUpdateCheckoutSettingsFailure(String(errorMessage)),
        )
      }
    }

  // updateCheckoutSettings used for checkout settings updating
  public updateCheckoutSettings =
    ({ storeId, checkoutSettings }: UpdateCheckoutSettingsOptions): any =>
    async (dispatch: AppDispatch) => {
      // start updating checkout settings
      dispatch(storeSlice.actions.getOrUpdateCheckoutSettings())

      try {
        // update checkout settings request
        const data = await this.api.updateCheckoutSettings({
          storeId,
          fields: {
            ...checkoutSettings,
          },
        })

        // update checkout settings succeeded
        dispatch(
          storeSlice.actions.getOrUpdateCheckoutSettingsSuccess(
            data.checkoutSettings || null,
          ),
        )

        // set mode to displaying
        dispatch(storeSlice.actions.setCheckoutSettingsMode(Modes.Displaying))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // updating checkout settings failed
        console.error(`failed to update checkout settings`, errorMessage)
        dispatch(
          storeSlice.actions.getOrUpdateCheckoutSettingsFailure(String(errorMessage)),
        )
      }
    }
}
