import { DefaultApi, Merchant } from '@softcery/qc-apiclient'

import { AppDispatch } from '~/app/redux'
import { getErrorMessage } from '~/shared/api/getErrorMessage'
import { Modes } from '~/types'

import { merchantDashboardSlice } from './slice'

// UpdateMerchantOptions is used to parameterize updateMerchant.
interface UpdateMerchantOptions {
  merchantId: string
  merchant: Merchant
}

export class MerchantDashboardActions {
  constructor(private api: DefaultApi) {}

  // getMerchant used for getting merchant info
  public getMerchant =
    (merchantId: string): any =>
    async (dispatch: AppDispatch) => {
      // start getting merchant
      dispatch(merchantDashboardSlice.actions.getOrUpdateMerchant())

      try {
        // get merchant request
        const data = await this.api.getMerchants({
          offset: 0,
          limit: 1,
          id: merchantId,
        })

        // get merchant succeeded
        dispatch(
          merchantDashboardSlice.actions.getOrUpdateMerchantSuccess(
            data.merchants?.[0] || null,
          ),
        )
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // getting merchant failed
        console.error(`failed to get merchant`, errorMessage)
        dispatch(
          merchantDashboardSlice.actions.getOrUpdateMerchantFailure(String(errorMessage)),
        )
      }
    }

  // updateMerchant used for merchant updating
  public updateMerchant =
    ({ merchantId, merchant }: UpdateMerchantOptions): any =>
    async (dispatch: AppDispatch) => {
      // start updating merchant
      dispatch(merchantDashboardSlice.actions.getOrUpdateMerchant())

      try {
        // update merchant request
        await this.api.updateMerchant({
          id: merchantId,
          fields: { merchant },
        })

        // update merchant succeeded
        dispatch(merchantDashboardSlice.actions.getOrUpdateMerchantSuccess(merchant))

        // set mode to displaying
        dispatch(merchantDashboardSlice.actions.setMerchantMode(Modes.Displaying))
      } catch (error) {
        const errorMessage = await getErrorMessage(error)

        // updating merchant failed
        console.error(`failed to update merchant`, errorMessage)
        dispatch(
          merchantDashboardSlice.actions.getOrUpdateMerchantFailure(String(errorMessage)),
        )
      }
    }
}
