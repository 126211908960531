import { FC, ReactElement } from 'react'
import { SerializedStyles } from '@emotion/react'
import { Loader, Error } from '~/shared/ui/atoms'
import { styles } from './styles'

interface CardProps {
  loading: boolean
  error: string
  showContent: boolean

  header: ReactElement
  extendStyle?: {
    container?: SerializedStyles
    body?: SerializedStyles
  }
}

export const Card: FC<CardProps> = ({
  loading,
  error,
  showContent,

  children,
  header,
  extendStyle,
}) => {
  return (
    <div
      css={[
        styles.container.base,
        loading && styles.container.loading,
        extendStyle?.container,
      ]}
    >
      {header}

      <div css={[styles.body.base, loading && styles.body.center, extendStyle?.body]}>
        {showContent && (
          <div css={[loading && styles.content.loading, error && styles.content.error]}>
            {!loading && children}
          </div>
        )}
        {loading && <Loader type="dark" />}
        {error && <Error>{error}</Error>}
      </div>
    </div>
  )
}
