import { FC } from 'react'
import { SerializedStyles } from '@emotion/react'
import { styles } from './styles'

interface IntegrationStatusProps {
  extendStyle?: SerializedStyles
}

export const IntegrationStatus: FC<IntegrationStatusProps> = ({
  extendStyle,
  children,
}) => {
  return <div css={[styles.error, extendStyle]}>{children}</div>
}
