import { GetMerchantsRequest, Merchant } from '@softcery/qc-apiclient'
import {
  combine,
  sample,
  createEffect,
  createEvent,
  createStore,
  restore,
} from 'effector'
import { debounce } from 'patronum'
import { deleteMerchantFx } from '~/features/merchant/delete-merchant'
import { apiService, getErrorMessage } from '~/shared/api'

const getFx = createEffect(async (requestOptions: GetMerchantsRequest) => {
  try {
    return await apiService().getMerchants({
      ...requestOptions,
      search: requestOptions.search?.trim(),
    })
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to get merchants`, errorMessage)
    return Promise.reject(errorMessage)
  }
})

export const get = createEvent<GetMerchantsRequest>()

debounce({
  source: get,
  timeout: 300,
  target: getFx,
})

export const $items = createStore<Merchant[]>([])
  .on(getFx.doneData, (_, payload) => payload.merchants)
  .reset(getFx.fail)

export const $totalItems = createStore<number>(0).on(
  getFx.doneData,
  (_, payload) => payload.totalMerchants,
)

export const setRequestOptions = createEvent<GetMerchantsRequest>()

export const $requestOptions = createStore<GetMerchantsRequest>({
  offset: 0,
  limit: 20,
  search: '',
}).on(setRequestOptions, (state, payload) => ({ ...state, ...payload }))

export const clearError = createEvent()

export const $getStatus = combine({
  loading: getFx.pending,
  error: restore(getFx.failData, null).reset(clearError),
})

sample({
  clock: deleteMerchantFx.done,
  source: $requestOptions,
  target: getFx,
})
