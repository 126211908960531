import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'
import { Label, Input, InputProps } from '../../atoms'
import { styles } from './styles'

interface Props extends InputProps {
  disabled?: boolean
  required?: boolean
  label: string
  extendStyles?: {
    container?: SerializedStyles
    label?: SerializedStyles
    inputContainer?: SerializedStyles
    inputBase?: SerializedStyles
  }
}

export const InputWithLabel: FC<Props> = ({
  disabled,
  required,
  extendStyles,
  label,
  ...props
}) => {
  return (
    <div css={[extendStyles?.container, disabled && styles.disabled]}>
      <div css={[styles.label, extendStyles?.label]}>
        <Label>{label}</Label>
        <span>{required && '*'}</span>
      </div>
      <div css={extendStyles?.inputContainer}>
        <Input {...props} extendStyle={{ input: extendStyles?.inputBase }} />
      </div>
    </div>
  )
}
