import {
  FC,
  useContext,
  useEffect,
  useMemo,
  Fragment,
  SetStateAction,
  Dispatch,
  useState,
} from 'react'
import Masonry from 'react-masonry-css'
import { push } from 'connected-react-router'
import { useRouteMatch } from 'react-router-dom'

import { useAppDispatch, useAppSelector, useClearStateOnUnmount } from '~/shared/model'
import { ApiContext } from '~/app/hoc/with-api'

import { getIntegrationFx } from '~/entities/integration'
import { DeleteStorePopup } from '~/features/store/delete-store'
import {
  CreateIntegrationPopup,
  CreateIntegrationInstructionPopup,
  Platforms,
} from '~/features/integration/create-integration'
import { IntegrationCard } from '~/features/integration/update-integration'

import {
  Info,
  Gateway,
  CheckoutStyles,
  Eye4fraudIntegration,
  CheckoutSettings,
  OrdersList,
} from './sections'
import { styles } from './styles'
import { MerchantDashboardActions } from '../dashboard/redux'
import { storeSlice } from './redux'

interface Props {
  showDeletePopup: boolean
  setShowDeletePopup: Dispatch<SetStateAction<boolean>>
}

export const Store: FC<Props> = ({ showDeletePopup, setShowDeletePopup }) => {
  const { params } = useRouteMatch<{ storeId: string }>()
  const [showCreateIntegrationPopup, setShowCreateIntegrationPopup] =
    useState<boolean>(false)
  const [
    showCreateIntegrationInstructionPopup,
    setShowCreateIntegrationInstructionPopup,
  ] = useState<boolean>(false)

  // get state and actions
  const dispatch = useAppDispatch()
  const { store } = useAppSelector((s) => s.store)
  const { merchant } = useAppSelector((s) => s.merchantDashboard)

  // initialize actions
  const { api } = useContext(ApiContext)
  const merchantActions = useMemo(() => new MerchantDashboardActions(api), [api])

  // clear redux state when screen unmounted
  useClearStateOnUnmount(storeSlice.actions.clearState())

  // need to display merchant name in header
  useEffect(() => {
    // get merchant when page reloaded
    if (store.store && !merchant.merchant) {
      dispatch(merchantActions.getMerchant(store.store.ownerMerchantId))
    }
  }, [store.store?.ownerMerchantId])

  // loading data for cards
  useEffect(() => {
    getIntegrationFx({ storeId: params.storeId })

    getIntegrationFx.doneData.watch((payload) => {
      if (
        payload.integration?.status !== 'active' &&
        !payload.integration?.isUsedPublicApp
      ) {
        setShowCreateIntegrationPopup(true)
      }
    })
  }, [])

  return (
    <Fragment>
      {showDeletePopup && (
        <DeleteStorePopup
          onDeleteComplete={() => {
            setShowDeletePopup(false)
            dispatch(push(`/merchant/${store.store?.ownerMerchantId}`))
          }}
          onClose={() => setShowDeletePopup(false)}
        />
      )}
      {showCreateIntegrationPopup && (
        <CreateIntegrationPopup
          setShowPopup={setShowCreateIntegrationPopup}
          setShowInstruction={() => setShowCreateIntegrationInstructionPopup(true)}
          vendorType={store.store?.vendorType as unknown as Platforms}
        />
      )}
      {showCreateIntegrationInstructionPopup && (
        <CreateIntegrationInstructionPopup
          onClose={() => setShowCreateIntegrationInstructionPopup(false)}
          vendorType={store.store?.vendorType as unknown as Platforms}
        />
      )}
      <div css={styles.container}>
        <Masonry
          breakpointCols={{
            default: 3,
            992: 2,
          }}
          css={styles.cards}
          className=""
        >
          <Info />
          <Gateway />
          <IntegrationCard
            setShowCreateIntegrationPopup={setShowCreateIntegrationPopup}
          />
          <CheckoutStyles />
          <Eye4fraudIntegration />
          <CheckoutSettings />
        </Masonry>
        {store.store?.id && <OrdersList />}
      </div>
    </Fragment>
  )
}
