import { DefaultApi, Configuration } from '@softcery/qc-apiclient'
import { API_URL } from '../config'
import { AUTH_TOKEN_KEY } from '../config/constants'

export const apiService = () =>
  new DefaultApi(
    new Configuration({
      basePath: API_URL,
      apiKey: `Bearer ${
        localStorage.getItem(AUTH_TOKEN_KEY) ||
        sessionStorage.getItem(AUTH_TOKEN_KEY) ||
        ''
      }`,
    }),
  )
