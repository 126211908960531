import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { LoginRequest } from '@softcery/qc-apiclient'
import { apiService } from '~/shared/api'
import { getErrorMessage } from '~/shared/api/getErrorMessage'

export const authUser = createAsyncThunk(
  '/authUser',
  async (
    requestOptions: {
      fields: LoginRequest
    },
    { rejectWithValue },
  ) => {
    try {
      return await apiService().login({
        fields: {
          ...requestOptions.fields,
        },
      })
    } catch (res: any) {
      const errorMessage = await getErrorMessage(res)
      console.error(`failed to log in`, errorMessage)
      return rejectWithValue(errorMessage)
    }
  },
)

const initialState = {
  user: {
    email: '',
    password: '',
  } as LoginRequest,
  saveSession: true,
  loading: false,
  error: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // set user email and password
    setUser: (state, action: PayloadAction<Partial<LoginRequest>>) => {
      state.user = { ...state.user, ...action.payload }
      // clear error
      state.error = ''
    },

    // set staySignIn
    setSaveSession: (state, action: PayloadAction<boolean>) => {
      state.saveSession = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    builder.addCase(authUser.fulfilled, (state) => {
      state.loading = false
      state.error = ''
    })
    builder.addCase(authUser.rejected, (state, action) => {
      state.error = action.payload as string
      state.loading = false
    })
  },
})
