import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: ${Colors.DARK_7};

    & > span {
      cursor: pointer;
    }

    & > img {
      margin: 0 16px;
    }
  `,
}
