import { FC, useEffect, Fragment, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useStore, useGate } from 'effector-react'
import { Integration } from '@softcery/qc-apiclient'

import {
  Card as BaseCard,
  TextField,
  CardHeader,
  TextSelect,
  IntegrationStatus,
} from '~/components'
import { Modes, CardHeaderTypes, IntegrationStatuses } from '~/types'
import { PLATFORM_OPTIONS } from '~/constants'
import { getIntegrationStatus as getIntegrationStatusFunc } from '~/shared/lib/getIntegrationStatus'
import {
  $getIntegrationStatus,
  getIntegrationFx,
  $integrationFields,
  $isIntegrationActive,
  setIntegration,
  IntegrationGate,
} from '~/entities/integration'
import {
  $updateIntegrationFields,
  setUpdateIntegrationFields,
  $updateIntegrationStatus,
  updateIntegration,
  updateIntegrationFx,
} from '~/features/integration/update-integration'

import { ENABLE_STATUS_OPTIONS, EnableStatuses } from '~/shared/config/constants'
import { ORDER_MODE_OPTIONS, TRANSACTION_MODE_OPTIONS } from '../../../constants'
import { styles } from './styles'

interface Props {
  setShowCreateIntegrationPopup: React.Dispatch<React.SetStateAction<boolean>>
}

export const Card: FC<Props> = ({ setShowCreateIntegrationPopup }) => {
  const { params } = useRouteMatch<{ storeId: string }>()

  const [displayingMode, setDisplayingMode] = useState<Modes>(Modes.Displaying)

  const getIntegrationStatus = useStore($getIntegrationStatus)
  const integrationFields = useStore($integrationFields)
  const isIntegrationActive = useStore($isIntegrationActive)

  const updateIntegrationStatus = useStore($updateIntegrationStatus)
  const updateIntegrationFields = useStore($updateIntegrationFields)

  const handleChange =
    (key: keyof Integration) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setUpdateIntegrationFields({ [key]: event.target.value })

  const handleSelect = (key: keyof Integration) => (value: string) =>
    setUpdateIntegrationFields({ [key]: value })

  const onEdit = (): void => {
    // set updated integration
    setUpdateIntegrationFields(integrationFields)

    setDisplayingMode(Modes.Editing)
  }

  const onCancelEditing = (): void => {
    // set updated integration to integration from api
    setUpdateIntegrationFields(integrationFields)

    setDisplayingMode(Modes.Displaying)
  }

  const onSave = (): void => {
    // update integration request
    updateIntegration({
      storeId: params.storeId,
    })
  }

  useEffect(() => {
    getIntegrationFx.doneData.watch((payload) => {
      if (payload.integration?.status === 'active') {
        setDisplayingMode(Modes.Displaying)
      }
    })

    updateIntegrationFx.doneData.watch((payload) => {
      setIntegration(payload.integration || {})

      setDisplayingMode(Modes.Displaying)
    })
  }, [])

  useGate(IntegrationGate)

  const disableSaveIntegration: boolean =
    updateIntegrationStatus.loading || Boolean(updateIntegrationStatus.error?.message)

  return (
    <BaseCard
      loading={
        !getIntegrationStatus.intervalFetch &&
        getIntegrationStatus.loading &&
        displayingMode === Modes.Displaying
      }
      error={updateIntegrationStatus.error?.message || ''}
      showContent={
        displayingMode === Modes.Editing ||
        (displayingMode === Modes.Displaying && !getIntegrationStatus.loading) ||
        Boolean(getIntegrationStatus.intervalFetch)
      }
      extendStyle={{ body: styles.cardBody }}
      header={
        <CardHeader
          mode={displayingMode}
          type={
            isIntegrationActive || integrationFields.isUsedPublicApp
              ? CardHeaderTypes.Edit
              : CardHeaderTypes.Create
          }
          title={
            <div css={styles.cardHeaderTitle}>
              INTEGRATION{' '}
              {isIntegrationActive && (
                <img
                  src={getIntegrationStatusFunc(integrationFields).icon}
                  alt="status"
                />
              )}
            </div>
          }
          onEdit={onEdit}
          onCreate={() => setShowCreateIntegrationPopup(true)}
          disableEditButton={Boolean(getIntegrationStatus.loading)}
          onSave={onSave}
          disableSaveButton={disableSaveIntegration}
          onDiscard={onCancelEditing}
        />
      }
    >
      <TextSelect
        title="Platform"
        value={integrationFields.type}
        mode={Modes.Displaying}
        selectOptions={PLATFORM_OPTIONS}
      />
      {integrationFields.type === 'shopify' && (
        <TextSelect
          title="Shop"
          staticValue={integrationFields.vendorId}
          mode={Modes.Displaying}
        />
      )}
      {integrationFields.type === 'bigcommerce' && (
        <TextSelect
          title="Access Key"
          staticValue={integrationFields.accessKey}
          mode={Modes.Displaying}
        />
      )}
      {integrationFields.status === IntegrationStatuses.Pending &&
        integrationFields.isUsedPublicApp && (
          <div css={styles.status.statusContainer}>
            <IntegrationStatus extendStyle={styles.status.pending}>
              <img
                src={getIntegrationStatusFunc(integrationFields)?.icon}
                alt="status"
                css={styles.statusIcon}
              />
              <span>{getIntegrationStatusFunc(integrationFields)?.text}</span>
            </IntegrationStatus>
          </div>
        )}
      {integrationFields.appDeletedAt && (
        <div css={styles.status.statusContainer}>
          <IntegrationStatus extendStyle={styles.status.pending}>
            App was deleted
          </IntegrationStatus>
        </div>
      )}
      {(integrationFields.type === 'shopify' ||
        (updateIntegrationFields.type === 'shopify' &&
          displayingMode === Modes.Editing)) && (
        <Fragment>
          <TextField
            title="API key"
            value="******"
            mode={displayingMode}
            useTextarea
            placeholder="shopify api key"
            inputValue={updateIntegrationFields.shopifyApiKey}
            onChange={handleChange('shopifyApiKey')}
          />
          <TextField
            title="API secret key"
            value="******"
            mode={displayingMode}
            useTextarea
            placeholder="shopify api secret key"
            inputValue={updateIntegrationFields.shopifyApiSecretKey}
            onChange={handleChange('shopifyApiSecretKey')}
          />
          <TextField
            title="“Check out” selectors"
            value={integrationFields.shopifyCheckoutSelectors}
            mode={displayingMode}
            useTextarea
            placeholder="#example, .example"
            inputValue={updateIntegrationFields.shopifyCheckoutSelectors}
            onChange={handleChange('shopifyCheckoutSelectors')}
          />
          <TextField
            title="“Buy now” classes"
            value={integrationFields.shopifyBuyNowClasses}
            mode={displayingMode}
            useTextarea
            placeholder=".example, .example-2 "
            inputValue={updateIntegrationFields.shopifyBuyNowClasses}
            onChange={handleChange('shopifyBuyNowClasses')}
          />
          <TextField
            title="Product title selector"
            value={integrationFields.shopifyProductTitleSelector}
            mode={displayingMode}
            useTextarea
            placeholder="#example, .example"
            inputValue={updateIntegrationFields.shopifyProductTitleSelector}
            onChange={handleChange('shopifyProductTitleSelector')}
          />
          <TextSelect
            title="Order Mode"
            value={integrationFields.shopifyOrderMode}
            mode={displayingMode}
            placeholder="Select mode"
            required
            selectOptions={ORDER_MODE_OPTIONS}
            selectValue={updateIntegrationFields.shopifyOrderMode}
            onChange={handleSelect('shopifyOrderMode')}
          />
        </Fragment>
      )}
      <TextSelect
        title="Transaction mode"
        value={integrationFields.manualTransactionCapture}
        mode={displayingMode}
        placeholder="Transaction mode"
        selectOptions={TRANSACTION_MODE_OPTIONS}
        selectValue={updateIntegrationFields.manualTransactionCapture}
        onChange={handleSelect('manualTransactionCapture')}
      />
      <TextSelect
        title="Status"
        value={integrationFields.enableStatus}
        mode={displayingMode}
        placeholder="Select status"
        selectOptions={ENABLE_STATUS_OPTIONS}
        selectValue={updateIntegrationFields.enableStatus}
        onChange={handleSelect('enableStatus')}
      />
      <TextSelect
        title="Debug Status"
        value={integrationFields.debugEnableStatus}
        mode={displayingMode}
        disabled={
          updateIntegrationFields.enableStatus === EnableStatuses.Disabled &&
          displayingMode === Modes.Editing
        }
        placeholder="Select status"
        selectOptions={ENABLE_STATUS_OPTIONS}
        selectValue={updateIntegrationFields.debugEnableStatus}
        onChange={handleSelect('debugEnableStatus')}
      />
    </BaseCard>
  )
}
