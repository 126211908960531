import { FC } from 'react'
import { Button, ButtonProps, Loader } from '../../atoms'

interface ButtonWithLoaderProps extends ButtonProps {
  loading?: boolean
}

export const ButtonWithLoader: FC<ButtonWithLoaderProps> = ({
  children,
  loading,
  disabled,
  ...props
}) => {
  return (
    <Button disabled={disabled || loading} {...props}>
      {loading ? <Loader type="light" /> : children}
    </Button>
  )
}
