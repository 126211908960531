import { css } from '@emotion/react'

export const styles = {
  error: css`
    width: 100%;

    padding: 16px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;

    border-radius: 8px;
  `,
}
