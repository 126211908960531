import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  pages: css`
    position: relative;

    margin-right: 24px;

    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
    color: ${Colors.DARK_7};

    white-space: nowrap;
    cursor: pointer;
  `,

  limitOptions: {
    container: css`
      position: absolute;
      left: 0;
      top: 10px;

      padding: 8px;

      display: flex;
      flex-direction: column;

      background: ${Colors.LIGHT_1};
      box-shadow: 0px 15px 25px ${Colors.LIGHT_13};
      border-radius: 5px;
    `,

    item: {
      base: css`
        padding: 8px;

        font-weight: normal;
        font-size: 12px;
        line-height: 10px;
        color: ${Colors.DARK_2};

        border-radius: 3px;
        cursor: pointer;

        &:hover {
          background: ${Colors.LIGHT_12};
        }
      `,

      active: css`
        background: ${Colors.LIGHT_12};
      `,
    },
  },

  arrow: {
    base: css`
      width: 32px;
      min-width: 32px;
      height: 32px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 5px;

      cursor: pointer;

      &:hover {
        background: ${Colors.LIGHT_10};
      }
    `,

    left: css`
      margin-right: 8px;
    `,

    disabled: css`
      opacity: 0.8;
      pointer-events: none;
    `,
  },
}
