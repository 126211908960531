import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  header: css`
    height: 46px;

    box-sizing: border-box;
    padding: 18px 32px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: ${Colors.LIGHT_1};
    user-select: none;
  `,

  item: css`
    display: flex;
    align-items: center;
  `,

  refresh: css`
    margin-right: 8px;
  `,
}
