import { FC } from 'react'
import { useGate } from 'effector-react'
import { Sidebar } from '~/widgets/sidebar'
import { BigcommerceConfigurationGate } from '~/entities/bigcommerce-configuration'
import { UpdateBigcommerceConfigurationCard } from '~/features/update-bigcommerce-configuration'
import { WithSidebar } from '~/shared/ui/layout'
import { styles } from './styles'

export const Settings: FC = () => {
  useGate(BigcommerceConfigurationGate)
  return (
    <WithSidebar
      sidebar={<Sidebar />}
      content={
        <div css={styles.container}>
          <UpdateBigcommerceConfigurationCard />
        </div>
      }
    />
  )
}
