import { keyframes } from '@emotion/react'

// standardized dimensions for adaptive markup
export const breakpoints = {
  mobile: `@media (max-width: 576px)`,
  tablet: `@media (max-width: 992px)`,
  pc: `@media (max-width: 1200px)`,
}

// spinning animation for loader
export const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

// colors for whole app
export enum Colors {
  DARK_1 = 'rgba(21, 31, 38, 0.75)',
  DARK_2 = 'rgba(21, 31, 38, 0.85)',
  DARK_3 = 'rgba(21, 31, 38, 0.3)',
  DARK_4 = '#151f26',
  DARK_5 = 'rgba(21, 31, 38, 0.35)',
  DARK_6 = 'rgba(21, 31, 38, 0.4)',
  DARK_7 = '#73797d',
  DARK_8 = '#8a8f93',
  DARK_9 = 'rgba(240, 93, 46, 0.25)',
  DARK_10 = 'rgba(0, 195, 55, 0.25)',

  LIGHT_1 = '#ffffff',
  LIGHT_2 = 'rgba(21, 31, 38, 0.03)',
  LIGHT_3 = 'rgba(255, 255, 255, 0.05)',
  LIGHT_4 = 'rgba(255, 255, 255, 0.1)',
  LIGHT_5 = 'rgba(255, 255, 255, 0.85)',
  LIGHT_6 = 'rgba(255, 255, 255, 0.3)',
  LIGHT_7 = 'rgba(248, 248, 248, 0.5)',
  LIGHT_8 = '#fbfbfb',
  LIGHT_9 = 'rgba(21, 31, 38, 0.01)',
  LIGHT_10 = '#f6f7fa',
  LIGHT_11 = 'rgba(21, 31, 38, 0.1)',
  LIGHT_12 = 'rgba(21, 31, 38, 0.03)',
  LIGHT_13 = 'rgba(0, 0, 0, 0.03)',
  LIGHT_15 = '#eff1f5',

  ACCENT_1 = '#ef4511',
  ACCENT_2 = '#dc3f10',
  ACCENT_3 = 'rgba(255, 70, 12, 0.58)',
  ACCENT_4 = 'rgba(240, 93, 46, 0.58)',

  SUCCESS_1 = '#00c337',
  SUCCESS_2 = '#00c437',
  SUCCESS_3 = 'rgba(0, 196, 55, 0.1)',
  SUCCESS_4 = 'rgba(0, 196, 55, 0.4)',
  SUCCESS_5 = 'rgba(0, 195, 55, 0.25)',

  WARNING_1 = '#ff9900',
  WARNING_2 = 'rgba(255, 199, 0, 0.1)',
  WARNING_3 = 'rgba(255, 138, 0, 0.4)',

  ERROR_1 = '#ff3d00',
  ERROR_2 = 'rgba(255, 61, 0, 0.1)',
  ERROR_3 = 'rgba(255, 61, 0, 0.4)',
}
