import { FC } from 'react'
import { SerializedStyles } from '@emotion/react'
import { styles } from './styles'

interface Props {
  extendStyle?: SerializedStyles
  dataTestId?: string
}

// Error provides error text.
// Used on Login, Create merchant screens and Error popup.
export const Error: FC<Props> = ({ extendStyle, children, dataTestId }) => {
  return (
    <div css={[styles.error, extendStyle]}>
      <span data-testid={dataTestId}>{children}</span>
    </div>
  )
}
