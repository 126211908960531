import { FC, Fragment } from 'react'
import { Store } from '@softcery/qc-apiclient'
import { useGate, useStore } from 'effector-react'

import { Textarea, TextSelect } from '~/components'
import { Error, H2 } from '~/shared/ui/atoms'
import {
  Popup as BasePopup,
  InputWithLabel,
  ButtonWithLoader,
} from '~/shared/ui/molecules'

import {
  createStoreFx,
  $createStoreFields,
  $createStoreStatus,
  CreateStoreGate,
  setCreateStoreFields,
} from '../../..'
import { styles } from './styles'
import { Modes } from '~/types'
import { PLATFORM_OPTIONS } from '~/constants'

interface Props {
  ownerMerchantId: string
  onClose: () => void
  onCreateComplete: (ownerMerchantId: string, storeId: string) => void
}

export const Popup: FC<Props> = ({ ownerMerchantId, onCreateComplete, onClose }) => {
  const { error, loading } = useStore($createStoreStatus)
  const fields = useStore($createStoreFields)

  const handleChange =
    (key: keyof Store) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setCreateStoreFields({ [key]: event.target.value })

  const handleSelect = () => (vendorType: string) => setCreateStoreFields({ vendorType })

  const onCreate = () =>
    createStoreFx({ ...fields, ownerMerchantId }).then((payload) => {
      onCreateComplete(ownerMerchantId, payload.storeId || '')
    })

  useGate(CreateStoreGate)

  return (
    <BasePopup
      title={<H2>New Store</H2>}
      onClose={onClose}
      content={
        <Fragment>
          <TextSelect
            title="Platform"
            mode={Modes.Editing}
            placeholder="Platform"
            selectOptions={PLATFORM_OPTIONS}
            selectValue={fields.vendorType}
            required
            onChange={handleSelect()}
            extendStyles={styles.textSelect}
          />
          <InputWithLabel
            label="Name"
            type="text"
            placeholder="Super Shop"
            required
            value={fields.name}
            onChange={handleChange('name')}
            extendStyles={{ inputContainer: styles.inputContainer, label: styles.label }}
          />
          <InputWithLabel
            label="Website"
            type="text"
            placeholder="https://example.com"
            value={fields.website}
            onChange={handleChange('website')}
            extendStyles={{ inputContainer: styles.inputContainer, label: styles.label }}
          />
          <Textarea
            label="Note"
            placeholder="Any additional info"
            value={fields.memoText || ''}
            onChange={handleChange('memoText')}
          />

          {error && <Error extendStyle={styles.error}>{error.message}</Error>}
        </Fragment>
      }
      footer={
        <ButtonWithLoader
          onClick={onCreate}
          disabled={Boolean(error) || !fields.name || !fields.vendorType}
          loading={loading}
        >
          CREATE STORE
        </ButtonWithLoader>
      }
    />
  )
}
