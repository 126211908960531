import { css } from '@emotion/react'

export const styles = {
  disabled: css`
    opacity: 0.6;
    pointer-events: none;
  `,

  label: css`
    display: flex;
  `,
}
