import { Fragment } from 'react'
import { Global } from '@emotion/react'
import Tracker from '@asayerio/tracker'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing/dist/browser/browsertracing'
import { CaptureConsole } from '@sentry/integrations/dist/captureconsole'
import { ENVIRONMENT } from '~/shared/config'
import { Routing } from '~/pages'
import { global } from './styles'
import { withProviders } from './hoc'

// TODO: maybe move it to processes layer
// initialize monitoring if not local
if (ENVIRONMENT !== 'local') {
  try {
    // start asayer tracker
    new Tracker({ projectID: 7688985397181348 }).start()
    console.debug('successfully initialized asayer')

    // start sentry
    Sentry.init({
      dsn: 'https://2b8ca5bfe32f4c93a48125222c92190c@o790881.ingest.sentry.io/5800349',
      integrations: [new BrowserTracing(), new CaptureConsole({ levels: ['error'] })],
      tracesSampleRate: 1.0,
    })
    console.debug('successfully initialized sentry')
  } catch (error) {
    console.error('failed to initialize asayer or sentry', error)
  }
}

function App() {
  return (
    <Fragment>
      <Global styles={global} />
      <Routing />
    </Fragment>
  )
}

export default withProviders(App)
