import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  base: css`
    display: block;

    /* margin-bottom: 8px; */

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${Colors.DARK_1};
  `,
}
