import { useEffect, FC, Fragment } from 'react'
import { Order } from '@softcery/qc-apiclient'

import { useAppDispatch, useAppSelector } from '~/shared/model'
import { Loader } from '~/shared/ui/atoms'
import { Toolbar, Pagination } from '~/shared/ui/molecules'
import { Table } from '~/shared/ui/organisms'

import { Item } from './components'
import { styles } from './styles'
import { getOrders, storeSlice } from '../../redux'

export const OrdersList: FC = () => {
  const dispatch = useAppDispatch()
  const { store } = useAppSelector((s) => s.store.store)
  const { loading, error, data, requestOptions } = useAppSelector((s) => s.store.orders)

  // get eye4fraud integration
  const { integration } = useAppSelector((s) => s.store.eye4fraudIntegration)

  // get orders when request options changed
  useEffect(() => {
    dispatch(
      getOrders({
        storeId: store?.id as string,
        ...requestOptions,
      }),
    )
  }, [requestOptions])

  return (
    <div>
      <Table
        loading={loading}
        error={error}
        toolbar={
          <Toolbar
            extendStyles={{
              container: styles.toolbar.container,
              title: { text: styles.toolbar.title.text },
            }}
            title="ORDER LIST"
            right={
              <Fragment>
                <div css={styles.loaderContainer}>
                  {loading && <Loader type="dark" />}
                </div>
                {data?.totalOrders && data.totalOrders > 0 && (
                  <Pagination
                    loading={loading}
                    total={data.totalOrders || 0}
                    pagination={requestOptions}
                    setPagination={(offset?: number, limit?: number) => {
                      dispatch(storeSlice.actions.setRequestOptions({ offset, limit }))
                    }}
                  />
                )}
              </Fragment>
            }
          />
        }
        tableRows={data.orders?.map((order: Order) => (
          <Item
            tableGridColumnWidth={styles.tableGridColumnWidth(
              integration?.enableStatus ? 7 : 6,
            )}
            order={order}
            showEye4fraudStatus={Boolean(integration?.enableStatus)}
            key={order.id}
          />
        ))}
        extendStyles={{
          tableHeaderContainer: styles.tableGridColumnWidth(
            integration?.enableStatus ? 7 : 6,
          ),
        }}
        tableHeads={[
          'Number',
          'Customer',
          'Products',
          'Total',
          'Created',
          'Status',
          integration?.enableStatus ? 'Eye4fraud Status' : '',
        ]}
        noItemsMessage="Order list is empty"
      />
    </div>
  )
}
