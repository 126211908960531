import React, { Fragment } from 'react'
import { LoginRequest, LoginResponse } from '@softcery/qc-apiclient'
import { AUTH_EMAIL_KEY, AUTH_TOKEN_KEY } from '~/shared/config/constants'
import { useAppDispatch, useAppSelector } from '~/shared/model'
import { Error, H2 } from '~/shared/ui/atoms'
import {
  InputWithLabel,
  ButtonWithLoader,
  CheckboxWithLabel,
  Popup as BasePopup,
} from '~/shared/ui/molecules'
import { authSlice, authUser } from '~/features/auth'
// import { viewerSlice } from '~/entities/viewer'
import { styles } from './styles'

export const Popup = () => {
  const dispatch = useAppDispatch()
  const { error, loading, user, saveSession } = useAppSelector((s) => s.auth)

  const handleChange =
    (key: keyof LoginRequest) => (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(authSlice.actions.setUser({ [key]: e.target.value }))

  const onSetChecked = () => dispatch(authSlice.actions.setSaveSession(!saveSession))

  const onSubmit = () => {
    dispatch(authUser({ fields: user })).then((action) => {
      const payload = action.payload as LoginResponse
      if (action.meta.requestStatus === 'fulfilled') {
        if (saveSession) {
          localStorage.setItem(AUTH_TOKEN_KEY, payload.authToken || '')
          localStorage.setItem(AUTH_EMAIL_KEY, user.email)
        } else {
          sessionStorage.setItem(AUTH_TOKEN_KEY, payload.authToken || '')
          sessionStorage.setItem(AUTH_EMAIL_KEY, user.email)
        }
        // TODO: delete reloading then when ApiContext hoc will be deleted
        window.location.reload()
        // dispatch(viewerSlice.actions.setToken(payload.authToken || ''))
      }
    })
  }

  return (
    <BasePopup
      extendStyle={{ container: styles.container, popup: styles.popup }}
      title={
        <div css={styles.title.container}>
          <H2>QuickCheckout</H2>
          <H2 extendStyle={styles.title.regular}>Admin</H2>
        </div>
      }
      content={
        <Fragment>
          <InputWithLabel
            label="Email"
            type="email"
            placeholder="email@example.com"
            value={user.email}
            onChange={handleChange('email')}
            dataTestId="email_input"
            extendStyles={{ inputContainer: styles.inputContainer, label: styles.label }}
          />
          <InputWithLabel
            label="Password"
            type="password"
            placeholder="●●●●●●●●●●●●●●"
            value={user.password}
            onChange={handleChange('password')}
            dataTestId="password_input"
            extendStyles={{ inputContainer: styles.inputContainer, label: styles.label }}
          />
          <CheckboxWithLabel
            label="Stay signed in"
            checked={saveSession}
            setChecked={onSetChecked}
            dataTestId="staySignedIn_checkbox"
          />

          {error && (
            <Error extendStyle={styles.error} dataTestId="login_error">
              {error}
            </Error>
          )}
        </Fragment>
      }
      footer={
        <ButtonWithLoader
          onClick={onSubmit}
          disabled={!user.email || !user.password || loading || Boolean(error)}
          extendStyle={styles.button}
          loading={loading}
          dataTestId="login_button"
        >
          SIGN IN
        </ButtonWithLoader>
      }
    />
  )
}
