import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

const tableGridTemplateColumns = '1fr 1fr 1fr 1fr 70px'

export const styles = {
  tableHeaderContainer: css`
    grid-template-columns: ${tableGridTemplateColumns};
  `,

  toolbar: {
    container: css`
      height: 50px;
      padding: 8px 16px;
      background: ${Colors.LIGHT_1};
      border-radius: 7px 7px 0 0;
      border-bottom: 1px solid ${Colors.LIGHT_11};
    `,

    title: {
      text: css`
        margin-left: 10px;
        font-size: 14px;
        font-weight: 600;
      `,
    },

    createButton: css`
      height: 35px;

      padding: 13px 16px;
      margin-right: 24px;

      font-weight: bold;
      font-size: 12px;
      line-height: 10px;

      border-radius: 6px;
    `,

    loaderContainer: css`
      margin-right: 24px;
    `,
  },

  tableRow: {
    container: css`
      grid-template-columns: ${tableGridTemplateColumns};
      box-sizing: border-box;
      padding: 10px 16px;

      display: grid;
      grid-gap: 10px;
      align-content: center;

      white-space: pre-wrap;
      word-break: break-word;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: ${Colors.DARK_1};

      background: ${Colors.LIGHT_1};
      border-bottom: 1px solid ${Colors.LIGHT_11};

      &:hover {
        // display actions
        #action {
          display: flex;
        }

        // hide options icon
        #options-icon {
          display: none;
        }
      }

      & > span {
        display: flex;
        align-items: center;
      }

      &:last-of-type {
        border-bottom: none;
      }

      span {
        display: flex;
        align-items: center;
      }
    `,
  },

  merchantActions: {
    container: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;
    `,

    action: {
      base: css`
        display: none;

        width: 35px;
        height: 35px;

        justify-content: center;
        align-items: center;

        border-radius: 5px;

        &:hover {
          background: ${Colors.LIGHT_12};
        }

        cursor: pointer;
      `,

      actionIcon: css`
        display: flex;
      `,
    },
  },

  noMerchants: css`
    height: 100%;
  `,
}
