import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    color: ${Colors.DARK_6};

    & > img {
      margin-right: 9px;
    }
  `,
}
