import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  button: {
    base: css`
      padding: 8px;

      display: flex;
      align-items: center;

      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 11px;
      color: ${Colors.DARK_8};

      border: none;
      background: none;

      cursor: pointer;

      &:focus {
        outline: none;
      }

      & > img {
        margin-left: 8px;
      }
    `,

    disabled: css`
      opacity: 0.8;
      pointer-events: none;
    `,
  },
}
