module.exports.combineEvents = require('./combine-events').combineEvents;
module.exports.condition = require('./condition').condition;
module.exports.debounce = require('./debounce').debounce;
module.exports.debug = require('./debug').debug;
module.exports.delay = require('./delay').delay;
module.exports.every = require('./every').every;
module.exports.inFlight = require('./in-flight').inFlight;
module.exports.pending = require('./pending').pending;
module.exports.reshape = require('./reshape').reshape;
module.exports.some = require('./some').some;
module.exports.splitMap = require('./split-map').splitMap;
module.exports.spread = require('./spread').spread;
module.exports.status = require('./status').status;
module.exports.throttle = require('./throttle').throttle;
