import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: css`
    display: inline-flex;
    align-items: center;

    cursor: pointer;
  `,

  checkbox: css`
    width: 18px;
    height: 18px;

    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${Colors.LIGHT_2};
    border: 1px solid ${Colors.DARK_3};
    border-radius: 5px;
  `,

  label: css`
    margin-left: 10px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${Colors.DARK_1};

    cursor: pointer;
  `,

  icon: css`
    display: block;
    width: 12px;
    height: 18px;
  `,
}
