import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  error: css`
    margin-top: 18px;
  `,

  tip: css`
    font-weight: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
    color: ${Colors.DARK_8};

    cursor: pointer;
  `,

  platform: {
    container: css`
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
    `,
    left: css`
      display: flex;
    `,

    title: css`
      margin: 0 4px;
    `,
  },

  popup: {
    inputContainer: css`
      margin-bottom: 16px;
    `,

    label: css`
      margin-bottom: 8px;
    `,

    error: css`
      margin-top: 18px;
    `,
  },

  status: {
    active: css`
      color: ${Colors.SUCCESS_2};

      background: ${Colors.SUCCESS_3};
      border: 1px solid ${Colors.SUCCESS_4};
    `,

    pending: css`
      color: ${Colors.WARNING_1};

      background: ${Colors.WARNING_2};
      border: 1px solid ${Colors.WARNING_3};
    `,

    failed: css`
      color: ${Colors.ERROR_1};

      background: ${Colors.ERROR_2};
      border: 1px solid ${Colors.ERROR_3};
    `,
  },

  statusIcon: css`
    margin-right: 10px;
  `,

  continueButton: css`
    margin-top: 32px;
  `,
}
