import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: {
    base: css`
      width: 100%;

      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    `,

    disabled: css`
      opacity: 0.6;
      pointer-events: none;
    `,
  },

  inputContainer: css`
    position: relative;

    display: flex;
    align-items: center;
  `,

  icon: css`
    position: absolute;
    right: 16px;

    cursor: pointer;
  `,

  input: css`
    display: block;
    width: 100%;
    height: 45px;

    box-sizing: border-box;
    padding: 14.5px 16px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: ${Colors.DARK_1};

    border-radius: 8px;
    background: ${Colors.LIGHT_2};
    border: 1px solid ${Colors.DARK_3};

    appearance: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${Colors.DARK_3};
    }
  `,

  // big dots in all browsers
  password: css`
    font-family: Verdana, sans-serif;
  `,
}
