import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  table: css`
    position: relative;
    width: 100%;

    font-family: Montserrat, sans-serif;
    font-style: normal;

    background: ${Colors.LIGHT_7};
    border-radius: 7px;
  `,

  header: {
    container: css`
      height: 55px;

      box-sizing: border-box;
      padding: 14px 16px;

      display: grid;
      grid-gap: 10px;
      align-content: center;

      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: ${Colors.DARK_2};

      background: ${Colors.LIGHT_1};
      border-bottom: 1px solid ${Colors.LIGHT_11};

      & > span {
        display: flex;
        align-items: center;
      }
    `,

    disabled: css`
      color: ${Colors.DARK_1};
    `,
  },

  tableBody: css`
    // -container paddings(32 + 32), -table header height(52 + 55)
    height: calc(100vh - 171px);
    overflow: auto;

    transition: 0.3s linear opacity;
  `,

  noItems: css`
    height: 100%;
    background: ${Colors.LIGHT_7};
    filter: drop-shadow(0px 15px 25px ${Colors.LIGHT_13});
    backdrop-filter: blur(1px);
  `,
}
