// icon and text for integration statuses
export const INTEGRATION_STATUSES = {
  active: {
    icon: require('../../../shared/assets/check.svg'),
    text: 'Integration installed',
  },
  pending: {
    icon: require('../../../shared/assets/tool.svg'),
    text: 'Integration pending',
  },
  failed: {
    icon: require('../../../shared/assets/alert.svg'),
    text: 'Integration failed',
  },
}
