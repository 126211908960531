import { useStore } from 'effector-react'
import {
  $merchantsRequestOptions,
  setMerchantsRequestOptions,
} from '~/entities/merchants'
import { Input as BaseInput } from '~/shared/ui/atoms'
import { styles } from './styles'

export const Input = () => {
  const { search } = useStore($merchantsRequestOptions)

  const onSearch = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    setMerchantsRequestOptions({
      offset: 0,
      search: e.target.value,
    })
  }

  return (
    <BaseInput
      onChange={onSearch()}
      placeholder="Search..."
      extendStyle={{ input: styles.base }}
      value={search}
    />
  )
}
