import { FC } from 'react'
import { styles } from './styles'
import { Error, Button, H2 } from '~/shared/ui/atoms'
import { Popup } from '~/shared/ui/molecules'

interface ErrorPopupProps {
  error: string

  reload: () => any
  closePopup: () => any
}

// ErrorPopup provides popup with error message.
// Used on Merchants screen.
export const ErrorPopup: FC<ErrorPopupProps> = ({ error, reload, closePopup }) => {
  return (
    <Popup
      title={<H2>Something went wrong</H2>}
      onClose={closePopup}
      content={
        <div css={styles.container}>
          <p css={styles.text}>
            We’ve experienced an unexpected error. If the problem persist, please contact
            the administrator.
          </p>

          <Error>{error}</Error>
        </div>
      }
      footer={<Button onClick={reload}>RELOAD PAGE</Button>}
    />
  )
}
