import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AUTH_TOKEN_KEY } from '~/shared/config/constants'

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState: {
    token: localStorage.getItem(AUTH_TOKEN_KEY) || sessionStorage.getItem(AUTH_TOKEN_KEY),
  },
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
})
