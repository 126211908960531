import { useStore, useGate } from 'effector-react'
import { FC } from 'react'
import { CardHeader, Card as BaseCard } from '~/components'
import { StoreRow, $stores, $getStoresStatus, StoresGate } from '~/entities/stores'
import { NoItems } from '~/shared/ui/atoms'
import { CardHeaderTypes, Modes } from '~/types'
import { styles } from './styles'

interface Props {
  setShowCreateStorePopup: React.Dispatch<React.SetStateAction<boolean>>
  ownerMerchantId: string
}

export const Card: FC<Props> = ({ setShowCreateStorePopup, ownerMerchantId }) => {
  const stores = useStore($stores)
  const getStoresStatus = useStore($getStoresStatus)
  useGate(StoresGate, ownerMerchantId)

  return (
    <BaseCard
      loading={getStoresStatus.loading}
      error={getStoresStatus.error?.message || ''}
      showContent={!getStoresStatus.error?.message}
      header={
        <CardHeader
          mode={Modes.Displaying}
          type={CardHeaderTypes.Create}
          title="STORES"
          onCreate={() => setShowCreateStorePopup(true)}
        />
      }
    >
      {stores.map((store) => (
        <StoreRow store={store} key={store.id} />
      ))}

      {stores.length === 0 &&
        !getStoresStatus.loading &&
        !getStoresStatus.error?.message && (
          <div css={styles.noItems}>
            <NoItems text="Create a store to get started" />
          </div>
        )}
    </BaseCard>
  )
}
