import { createGate } from 'effector-react'
import { BigcommerceConfiguration } from '@softcery/qc-apiclient'
import { combine, createEffect, createStore, forward, restore } from 'effector'
import { apiService, getErrorMessage } from '~/shared/api'

export const getFx = createEffect(async () => {
  try {
    return await apiService().getBigcommerceConfiguration()
  } catch (res: any) {
    const errorMessage = await getErrorMessage(res)
    console.error(`failed to get bigcommerce configuration`, errorMessage)
    return Promise.reject(errorMessage)
  }
})

export const $store = createStore<BigcommerceConfiguration>({}).on(
  getFx.doneData,
  (_, payload) => payload._configuration,
)

export const Gate = createGate()

forward({ from: Gate.open, to: getFx })

export const $getStatus = combine({
  loading: getFx.pending,
  error: restore(getFx.failData, null),
})
