import { FC } from 'react'
import { SerializedStyles } from '@emotion/react'
import { Order } from '@softcery/qc-apiclient'

import { styles } from './styles'
import { Status } from '..'

interface TableItemProps {
  order: Order
  tableGridColumnWidth: SerializedStyles
  showEye4fraudStatus: boolean
}

export const Item: FC<TableItemProps> = ({
  order,
  tableGridColumnWidth,
  showEye4fraudStatus,
}) => {
  return (
    <div css={[styles.container, tableGridColumnWidth]}>
      <span>{order.vendorOrderNumber || '-'}</span>
      <span>{`${order.firstName} ${order.lastName}` || '–'}</span>
      <span>
        {order.products?.[0].title}
        {order.products
          ? order.products.length > 1 && (
              <span css={styles.restProducts}>{` + ${order.products!.length - 1}`}</span>
            )
          : '–'}
      </span>
      <span>{`${order.currency}  ${parseFloat(order.totalPrice!).toFixed(2)}`}</span>
      <span>
        {new Date((order.createdAt as string).toString()).toLocaleDateString('ru')}
      </span>
      <Status value={order.status} />
      {showEye4fraudStatus && <span>{order.eye4fraudStatus || '-'}</span>}
    </div>
  )
}
