import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  container: {
    base: css`
      width: 100%;
      max-width: 423px;

      font-family: Montserrat, sans-serif;
      font-style: normal;

      box-shadow: 0px 15px 25px ${Colors.LIGHT_13};
      border-radius: 7px;
    `,

    loading: css`
      pointer-events: none;
    `,
  },

  body: {
    base: css`
      min-height: 244px;

      box-sizing: border-box;
      padding: 16px;

      background: ${Colors.LIGHT_1};
      border-radius: 0 0 7px 7px;
    `,

    center: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,
  },

  content: {
    loading: css`
      opacity: 0.8;
    `,

    error: css`
      margin-bottom: 16px;
    `,
  },
}
