import { FC } from 'react'
import { Loader } from '~/shared/ui/atoms'
import { styles } from './styles'

interface Props {
  text?: string
  showArrow?: boolean
  loading?: boolean

  onClick: () => void
}

export const Item: FC<Props> = ({
  text,
  showArrow,
  loading,

  onClick,
}) => (
  <div css={styles.container}>
    {showArrow && <img src={require('./assets/chevron-right.svg')} alt="chevron right" />}
    {loading ? <Loader type="dark" /> : <span onClick={onClick}>{text}</span>}
  </div>
)
