import { css } from '@emotion/react'

export const styles = {
  noItems: css`
    // card body(244px) - 32px(paddings)
    min-height: 212px;

    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
