import { css } from '@emotion/react'

export const styles = {
  container: css`
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;

    user-select: none;
  `,

  title: {
    container: css`
      display: flex;
      align-items: center;
    `,
  },

  right: css`
    display: flex;
    align-items: center;
  `,
}
