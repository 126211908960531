import { FC, useContext, useEffect, useMemo, Fragment, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '~/shared/model'
import { ApiContext } from '~/app/hoc/with-api'
import { Card, TextField, CardHeader, TextSelect, IntegrationStatus } from '~/components'
import { Modes, CardHeaderTypes, Status, IntegrationStatuses } from '~/types'
import { INTEGRATION_AND_GATEWAY_STATUSES } from '~/constants'

import { styles } from '../../styles'
import { StoreActions, storeSlice } from '../../redux'
import { GATEWAY_OPTIONS } from '../../constants'
import { MatchParams } from '../../types'

export const Gateway: FC = () => {
  // get state and actions
  const dispatch = useAppDispatch()
  const { gateway } = useAppSelector((t) => t.store)

  // initialize actions
  const { api } = useContext(ApiContext)
  const storeActions = useMemo(() => new StoreActions(api), [api])

  // get route params
  const { params } = useRouteMatch<MatchParams>()

  // show success status local storage key
  const SHOW_SUCCESS_STATUS = `@qc/show-success-gateway-status-${params.storeId}`

  // state for displaying gateway status
  const [showGatewayStatus, setShowGatewayStatus] = useState<boolean>(false)

  // success status displays only one time
  useEffect(() => {
    if (gateway.gateway?.type) {
      // get from local storage
      const statusAlreadyShowed = Boolean(localStorage.getItem(SHOW_SUCCESS_STATUS))

      // set showing gateway status
      setShowGatewayStatus(!statusAlreadyShowed)

      // set value to local storage
      if (!statusAlreadyShowed) {
        localStorage.setItem(SHOW_SUCCESS_STATUS, String(true))
      }
    }
  }, [gateway.gateway?.type])

  // get gateway, set mode to displaying
  useEffect(() => {
    // get store
    dispatch(storeActions.getGateway(params.storeId))

    // set mode to displaying
    dispatch(storeSlice.actions.setGatewayMode(Modes.Displaying))
  }, [])

  // start gateway editing
  const editGateway = (): void => {
    // set updated gateway
    dispatch(storeSlice.actions.setUpdatedGateway(gateway.gateway))

    // change mode
    dispatch(storeSlice.actions.setGatewayMode(Modes.Editing))
  }

  // cancel gateway editing
  const cancelGatewayEditing = (): void => {
    // set updated gateway to gateway from api
    dispatch(storeSlice.actions.setUpdatedGateway(gateway.gateway))

    // set mode to displaying
    dispatch(storeSlice.actions.setGatewayMode(Modes.Displaying))
  }

  // save edited gateway
  const saveGateway = (): void => {
    // update gateway request
    dispatch(
      storeActions.updateGateway({
        storeId: params.storeId,
        gateway: gateway.updatedGateway!,
      }),
    )
  }

  // gateway status icon and text
  const gatewayStatus: Status = gateway.gateway?.type
    ? INTEGRATION_AND_GATEWAY_STATUSES.active
    : INTEGRATION_AND_GATEWAY_STATUSES.pending

  const gatewaysFieldsValidation = () => {
    switch (gateway?.updatedGateway?.type) {
      case 'usaepay':
        return (
          !gateway.updatedGateway?.usaepayApiKey || !gateway.updatedGateway?.usaepayApiPin
        )
      case 'authorize.net':
        return (
          !gateway.updatedGateway?.authorizeNetLoginId ||
          !gateway.updatedGateway?.authorizeNetTransactionKey
        )
      default:
        return false
    }
  }

  // disable save gateway button
  const disableSaveGateway: boolean =
    gateway.loading || Boolean(gateway.error) || gatewaysFieldsValidation()

  return (
    <Card
      loading={gateway.loading && gateway.mode === Modes.Displaying}
      error={gateway.error}
      showContent={
        gateway.mode === Modes.Editing ||
        (gateway.mode === Modes.Displaying && !gateway.error)
      }
      extendStyle={{ body: styles.cardBody }}
      header={
        <CardHeader
          mode={gateway.mode}
          type={CardHeaderTypes.Edit}
          title={
            <div css={styles.cardHeaderTitle}>
              GATEWAY {gateway.gateway && <img src={gatewayStatus.icon} alt="status" />}
            </div>
          }
          onEdit={editGateway}
          disableEditButton={Boolean(gateway.error)}
          onSave={saveGateway}
          disableSaveButton={disableSaveGateway}
          onDiscard={cancelGatewayEditing}
        />
      }
    >
      <TextSelect
        title="Payment gateway"
        value={gateway.gateway?.type}
        mode={gateway.mode}
        placeholder="Select gateway"
        required
        selectOptions={GATEWAY_OPTIONS}
        selectValue={gateway.updatedGateway?.type}
        onChange={(type) => dispatch(storeSlice.actions.setUpdatedGateway({ type }))}
      />

      {gateway.updatedGateway?.type === 'usaepay' && gateway.mode === Modes.Editing && (
        <Fragment>
          <TextField
            title="API Key"
            value={gateway.gateway?.usaepayApiKey}
            mode={gateway.mode}
            placeholder="example-api-key"
            required
            inputValue={gateway.updatedGateway?.usaepayApiKey}
            onChange={(e) =>
              dispatch(
                storeSlice.actions.setUpdatedGateway({ usaepayApiKey: e.target.value }),
              )
            }
          />
          <TextField
            title="API Pin"
            value={gateway.gateway?.usaepayApiPin}
            mode={gateway.mode}
            placeholder="example-api-pin"
            required
            inputValue={gateway.updatedGateway?.usaepayApiPin}
            onChange={(e) =>
              dispatch(
                storeSlice.actions.setUpdatedGateway({
                  usaepayApiPin: e.target.value,
                }),
              )
            }
          />
        </Fragment>
      )}

      {gateway.updatedGateway?.type === 'authorize.net' &&
        gateway.mode === Modes.Editing && (
          <Fragment>
            <TextField
              title="Login id"
              value={gateway.gateway?.authorizeNetLoginId}
              mode={gateway.mode}
              placeholder="4nATfg86J"
              required
              inputValue={gateway.updatedGateway?.authorizeNetLoginId}
              onChange={(e) =>
                dispatch(
                  storeSlice.actions.setUpdatedGateway({
                    authorizeNetLoginId: e.target.value,
                  }),
                )
              }
            />
            <TextField
              title="Transaction key"
              value={gateway.gateway?.authorizeNetTransactionKey}
              mode={gateway.mode}
              placeholder="5X6b23MvZX5aQakG"
              required
              inputValue={gateway.updatedGateway?.authorizeNetTransactionKey}
              onChange={(e) =>
                dispatch(
                  storeSlice.actions.setUpdatedGateway({
                    authorizeNetTransactionKey: e.target.value,
                  }),
                )
              }
            />
          </Fragment>
        )}

      {gateway.gateway?.type && showGatewayStatus && (
        <IntegrationStatus
          extendStyle={
            styles.status[
              gateway.gateway?.type
                ? IntegrationStatuses.Active
                : IntegrationStatuses.Pending
            ]
          }
        >
          <img src={gatewayStatus.icon} alt="status" css={styles.statusIcon} />
          <span>{gatewayStatus.text}</span>
        </IntegrationStatus>
      )}
    </Card>
  )
}
