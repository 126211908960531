// modes for Card component
// used to display data, or edit data
export enum Modes {
  Displaying = 'displaying',
  Editing = 'editing',
}

// types for CardHeader component
// used to display various buttons in CardHeader
export enum CardHeaderTypes {
  Create = 'create',
  Edit = 'edit',
}

// statuses
export enum IntegrationStatuses {
  Active = 'active',
  Pending = 'pending',
  Failed = 'failed',
}

// status text and icon
export interface Status {
  icon: string
  text: string
}
