// options for payment gateway select
export const GATEWAY_OPTIONS = [
  { title: 'USAePay', value: 'usaepay' },
  { title: 'Authorize.Net', value: 'authorize.net' },
]

// marketing checkbox options
export const MARKETING_CHECKBOX_VISIBILITY_OPTIONS = [
  { title: 'Show', value: 'show' },
  { title: 'Hide', value: 'hide' },
]
export const MARKETING_CHECKBOX_DEFAULT_STATE_OPTIONS = [
  { title: 'Checked', value: 'checked' },
  { title: 'Unchecked', value: 'unchecked' },
]
