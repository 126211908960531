import { css } from '@emotion/react'
import { breakpoints } from '~/shared/styles'

export const styles = {
  container: css`
    padding: 32px;
    box-sizing: border-box;

    display: flex;
    align-items: flex-start;
  `,

  cards: css`
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
    gap: 24px;

    ${breakpoints.tablet} {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 730px) {
      justify-items: center;
      grid-template-columns: 1fr;
    }
  `,
}
