import { FC, Fragment, useState } from 'react'
import { styles } from './styles'

interface Props {
  loading: boolean
  total: number
  pagination: {
    limit?: number
    offset?: number
  }
  setPagination: (offset?: number, limit?: number) => void
}

export const Pagination: FC<Props> = ({ loading, total, pagination, setPagination }) => {
  const [showLimitOptions, setShowLimitOptions] = useState<boolean>(false)

  // get previous page
  const increaseOffset = (offset: number, limit: number): number =>
    offset - limit < 0 ? 0 : offset - limit

  // get next page
  const decreaseOffset = (offset: number, limit: number): number =>
    offset + limit > total ? total : offset + limit

  // current portion of items and total items number
  const getPagesText = (offset: number, limit: number): string =>
    `${offset! + 1}-${offset! + limit! >= total ? total : offset! + limit!} of ${total}`

  return (
    <Fragment>
      <div css={styles.pages} tabIndex={0} onBlur={() => setShowLimitOptions(false)}>
        <span onClick={() => setShowLimitOptions(true)}>
          {getPagesText(pagination.offset!, pagination.limit!)}
        </span>

        {showLimitOptions && (
          <div css={styles.limitOptions.container}>
            {[20, 50, 100].map((limit) => (
              <span
                onClick={() => {
                  setPagination(pagination.offset, limit)
                  setShowLimitOptions(false)
                }}
                css={[
                  styles.limitOptions.item.base,
                  limit === pagination.limit && styles.limitOptions.item.active,
                ]}
              >
                {limit} per page
              </span>
            ))}
          </div>
        )}
      </div>
      <div
        css={[
          styles.arrow.base,
          styles.arrow.left,
          (pagination.offset === 0 || loading) && styles.arrow.disabled,
        ]}
        onClick={() => {
          setPagination(
            increaseOffset(pagination.offset!, pagination.limit!),
            pagination.limit,
          )
        }}
      >
        <img src={require('./assets/arrow-left.svg')} alt="arrow left" />
      </div>
      <div
        css={[
          styles.arrow.base,
          (pagination.offset! + pagination.limit! >= total || loading) &&
            styles.arrow.disabled,
        ]}
        onClick={() =>
          setPagination(
            decreaseOffset(pagination.offset!, pagination.limit!),
            pagination.limit,
          )
        }
      >
        <img src={require('./assets/arrow-right.svg')} alt="arrow right" />
      </div>
    </Fragment>
  )
}
