import { css } from '@emotion/react'
import { Colors } from '~/shared/styles'

export const styles = {
  popup: {
    container: css`
      z-index: 4;
    `,

    popup: css`
      max-width: 750px;
      max-height: 600px;
      overflow: auto;
    `,
  },

  instruction: css`
    h3 {
      margin: 32px 0 16px 0;

      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      color: ${Colors.DARK_2};
    }

    a {
      text-decoration: underline;
      color: ${Colors.DARK_2};
    }

    hr {
      margin: 24px 0 0 0;

      border: none;
      border-top: 2px solid ${Colors.LIGHT_15};
    }

    p,
    ol {
      margin: 0;

      font-weight: normal;
      font-size: 16px;
      line-height: 22px;

      color: ${Colors.DARK_2};
    }

    code {
      font-style: italic;
    }

    table {
      margin-top: 16px;

      border-spacing: 0;
      border: 1px solid ${Colors.LIGHT_15};
      border-radius: 5px;

      th {
        padding: 8px 16px;

        text-align: left;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: ${Colors.DARK_1};

        background: ${Colors.LIGHT_10};

        // first th
        &:first-of-type {
          border-right: 1px solid #dcdddf;
        }
      }

      tr {
        // last tr
        &:last-of-type {
          // last two tds
          td:first-of-type {
            border-radius: 0 0 0 5px;
          }
          td:last-of-type {
            border-radius: 0 0 5px 0;
          }
        }
      }

      td {
        padding: 8px 16px;

        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: ${Colors.DARK_1};

        background: ${Colors.LIGHT_1};
        border-top: 1px solid ${Colors.LIGHT_15};

        // first td in tr
        &:first-of-type {
          border-right: 1px solid ${Colors.LIGHT_15};
        }
      }
    }
  `,
}
